import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading)
Vue.component('loading', Loading)

// name is optional
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

// Vue Modal
import VModal from 'vue-js-modal'
Vue.use(VModal)

// Tailwind
import '@/assets/css/main.css'

import './assets/scss/main.scss'

Vue.config.productionTip = false

store.dispatch('init').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})