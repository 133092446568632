import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutMain from '@/views/layouts/Main'
import Game from '@/views/pages/Game.vue'
import PrivateArea from '@/views/pages/PrivateArea.vue'
import GameResult from '@/views/pages/GameResult.vue'
import Guide from '@/views/pages/Guide.vue'
import Privacy from '@/views/pages/Privacy';
import TermsOfUse from '@/views/pages/TermsOfUse';


Vue.use(VueRouter)

const routes = [
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
  {
    path: '/game/training',
    name: 'Training',
    component: Game,
    props: () => ({ gameType: 0 }),
    meta: {
      auth: true
    }
  },
  {
    path: '/game/:id/result',
    name: 'GameResult',
    component: GameResult,
    props: route => ({ gameId: route.params.id }),
    meta: {
      auth: true
    }
  },
  {
    path: '/game/:id',
    name: 'Game',
    component: Game,
    props: route => ({ gameId: route.params.id, gameType: 1 }),
    meta: {
      auth: true
    }
  },
  {
    path: '/guide',
    name: 'guide',
    component: Guide
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/terms-of-use',
    name: 'privacy',
    component: TermsOfUse
  },
  {
    path: '*',
    component: LayoutMain,
    children: [
      {
        path: '/not-found',
        name: 'NotFound',
        component: PrivateArea
      }
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // next(false)

  // If playerId and gameId are not given, navigate to not found page.
  if(
    (
      undefined == to.query.playerId || 
      null == to.query.playerId
    ) && 
      'NotFound' != to.name
    ) {
    next({
      name: 'NotFound'
    })
  }

  next()
});

export default router
