import request from './request'

const api = {
  retrieveGame(payload) {
    return request.get(`/gameplay/${payload.gameId}`, {params: {playerId: payload.playerId}})
  },

  createTrainingGame(playerId) {
    return request.get(`/gameplay/training/${playerId}`)
  },

  updateGame(payload) {
    return request.put(`/gameplay/${payload.id}`, payload.data, {params: {playerId: payload.playerId}})
  },

  finalizeGame(payload) {
    return request.post(`/gameplay/finalize`, payload.data, {params: {playerId: payload.playerId}})
  },

  usePowerup(payload) {
    return request.delete(`/gameplay/${payload.id}/power-up/${payload.key}`, {params: {playerId: payload.playerId}})
  },

  heartbeat() {
    return request.get(`/heartbeat`)
  }
}
export default api