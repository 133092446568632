<template>
  <div class="layout-main vld-parent">
    <loading :active.sync="this.$store.state.isLoading" 
        :is-full-page="true"
        :lock-scroll="true" 
        :backgroundColor="`#000000`"
        :color="`#FFB74F`"
        :blur="`7px`"
        :opacity="0.5"
        :width="64"
        :height="64"
        ></loading>

    <div class="nav-wrapper">
      <header class="flex items-center">
        <div class="container mx-auto max-w-sm px-4 items-center" :class="[this.$store.state.authenticated ? 'grid grid-cols-3 gap-4' : '' ]">
          <div class="logo" :class="[this.$store.state.authenticated ? 'flex items-center' : '' ]">
            <router-link :to="{name: 'dashboard', query: this.$route.query}"><img :src="require('@/assets/logo.svg')" alt="" :class="[this.$store.state.authenticated ? '' : 'mx-auto']"></router-link>
          </div>
        </div>
      </header>
    </div>
    <div class="main mx-auto">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll)
  },
  watch: {
    $route() {
      this.closeMenu();
    }
  },
  beforeDestroy(){
    document.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onClickMenu() {
      var element = document.querySelector(".nav-wrapper");
      element.classList.toggle("active");
      document.body.classList.toggle("overflow-hidden");
    },
    closeMenu() {
      var element = document.querySelector(".nav-wrapper");
      element.classList.remove("active");
      document.body.classList.remove("overflow-hidden");
    },
    onScroll() {
      if(window.pageYOffset > 0){
        document.querySelector(".nav-wrapper").classList.add("scrolled")
      }
      else {
        document.querySelector(".nav-wrapper").classList.remove("scrolled")
      }
    }
  }
}
</script>

<style>

</style>