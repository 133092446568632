<template>
  <div class="home">
    <div class="flex flex-col items-center justify-center p-8 guide">
        <h2>Gizlilik Sözleşmesi</h2>
        <div class="gizlilik">
          <div>
              <div>
                <div>
                    <div>
                      <table >
                          <tbody>
                            <tr>
                                <td >
                                  <div>
                                      <p>Yürürlüğe Girdiği Tarih:</p>
                                  </div>
                                </td>
                                <td >
                                  <div>
                                      <p>29.10.2020</p>
                                  </div>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                  <div class='gizlilik'>
                                      <p>Son Güncelleme Tarihi:</p>
                                  </div>
                                </td>
                                <td >
                                  <div>
                                      <p>29.10.2020</p>
                                  </div>
                                </td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                </div>
              </div>
              <p>Odit A.Ş; olarak bizler, dünyayı daha eğlenceli ve hayatı daha kolay bir hale getirmek için yola çıktık. Bu doğrultuda sunduğumuz oyun ve uygulamalar ile sizlere en iyi deneyimi sunmaya çabalıyoruz. Ancak size &ouml;zel deneyimler sunabilmemiz için, sizi tanımamız gerekiyor ve bu sebeple sizlerden bir takım kişisel bilgilerinizi almak durumundayız.</p>
              <p>Bu hususta topladığımız bilgiler, bu bilgilerin saklanılma şekil ve süreleri, kimlerle ve ne amaçla paylaşıldıkları ve ne amaçla kullanıldıkları hakkında detayları işbu s&ouml;zleşmede sizlerle şeffaf olarak paylaşmayı ve sizleri oyun ve uygulamalarımızı bilinçli bir şekilde kullanabilmeniz için doğru bilgilendirmeyi birincil g&ouml;revimiz olarak g&ouml;rmekteyiz.</p>
              <p>İşbu s&ouml;zleşmede yer alan bilgiler s&ouml;zleşmenin ilgili maddelerinde belirtilen oyun, uygulama ve servislerimiz ile ilgili bilgileri içermekte olup, dikkatinize sunulmaktadır. İlgili oyun, uygulama ve servislerimizi kullandığınız taktirde, işbu s&ouml;zleşmede belirtilen şartları okuduğunuzu ve kabul ettiğinizi beyan etmiş sayılırsınız.</p>
              <p>iletişime geçebilirsiniz.</p>
              <h2>1. GİRİŞ</h2>
              <p>Odit A.Ş; olarak mobil cihazlar, web tarayıcıları, masa üstü ortamları ve diğer dijital ortamlar için oyun, uygulama ve dijital hizmetler sunmaktayız. İş bu doküman boyunca Odit'un ürettiği ve satışını yaptığı tüm bu hizmetlere kısaca 'servis' (çoğul olarak 'servisler') diyeceğiz.</p>
              <p>İşbu s&ouml;zleşmede belirtilen şartlar Odit A.Ş; ve tüm yan, alt ve üst iştiraklerine ait servisleri kapsamakta olup, işbu s&ouml;zleşme süresince Odit A.Ş;, 'Odit' veya 'biz' olarak tanımlanacaktır.</p>
              <h2>1.1. VERİ Y&Ouml;NETİCİSİ</h2>
              <p>Toplanan tüm kişisel veriler ile ilgili yasal veri y&ouml;neticisi Odit A.Ş; olup, verilerin saklandığı merkezlerin konum ve diğer niteliklerinden bağımsız olarak, verilerin tamamının erişim hakları sadece Odit A.Ş;'dedir ve sadece güvenlik kontrolü, sistem y&ouml;netimi vb. işlemler için sadece Odit A.Ş; tarafından sertifikalandırılmış s&ouml;zleşmeli üçüncü partilerle çalışılmaktadır.</p>
              <h2>1.2. YASAL YETKİ ALANI</h2>
              <p>İkamet ettiğiniz konum veya vatandaşı olduğunuz ülkeye g&ouml;re ayrı kanunlara tabi olabiliriz ve bundan farklı düzenlemelerle servislerimizi sunuyor olabiliriz.</p>
              <p>İşbu s&ouml;zleşme boyunca bütün Avrupa Birliği ve Avrupa Ekonomi Topluluğu ile İsviçre'ye 'Avrupa ülkeleri', Türkiye'ye 'Türkiye', Kuzey Kıbrıs Türk Cumhuriyet, Azerbaycan, Kırgızistan, Kazakistan, Türkmenistan ve &Ouml;zbekistan'a 'Türki Devletler', A.B.D. ve Kanada'ya 'Kuzey Amerika ülkeleri' ve diğer ülkelere 'Diğer ülkeler' diyeceğiz.</p>
              <h2>1.3. YüRüRLüK</h2>
              <p>İşbu Gizlilik S&ouml;zleşmesi servislerimizi kullanmaya başladığınız andan itibaren veya Odit ve bağlı şirketlerinin web siteleri veya servislere ait web sitelerine eriştiğiniz anda yürürlüğe girer.</p>
              <p>Eğer Odit ve servisleri tarafından kişisel bilgilerinizin toplanmasını ve işlenmesini istemiyorsanız, servislerimizi kullanmayı derhal durdurmalısınız.</p>
              <p>Servislerimizi kullanmayı bırakmak ve servislerimizin içerisinde sunulan hesap silme işlevlerini çalıştırmak sizin sorumluluğunuzdadır.</p>
              <h2>2. TOPLADIĞIMIZ VERİLER VE VERİ TOPLAMA Y&Ouml;NTEMLERİ</h2>
              <p>Bize kendi rızanızla sunmuş olduğunuz tüm verileri toplayarak saklamaktayız. İşbu veriler doğrudan servislerimizde yer alan formlar veya servislerimizin üstünde çalıştığı cihazların &ouml;zelliklerine erişimimize verdiğiniz onaylar dahilde cihazlardan edindiğimiz bilgilerden oluşmaktadır.</p>
              <h2>2.1. DOĞRUDAN PAYLAŞTIĞINIZ BİLGİLERİNİZ</h2>
              <p>Bizlerle servislerimizde yer alan formları doldurarak paylaştığınız bilgileriniz, 'doğrudan' paylaştığınız bilgiler olarak tanımlanmaktadır ve hesap oluşturma, destek ekibimizle iletişime geçme vb. işlemler sırasında bizimle paylaştığınız bilgilerden oluşur.</p>
              <p>Sizlerden servislerimizde yer alan formlar aracılığı ile doğrudan topladığımız geleneksel veriler servislerimize g&ouml;re farklılık g&ouml;stermekle birlikte aşağıdaki gibidir:</p>
              <ul>
                <li>adınız ve soyadınız (ikincil isim ve soyadlar dahil olarak),</li>
                <li>kullanıcı rumuzunuz,</li>
                <li>cinsiyetiniz ve/veya cinsel tercihiniz,</li>
                <li>yaşınız ve/veya doğum tarihiniz,</li>
                <li>e-posta adresiniz ve/veya telefon numaranız,</li>
                <li>profil fotoğrafınız,</li>
                <li>sosyal medya hesap linkleriniz,</li>
                <li>parolanız ve</li>
                <li>sizi tanımlamamıza ve deneyiminizi iyileştirmemizde bize yardımcı olabilecek diğer bilgileriniz.</li>
              </ul>
              <p>&nbsp;</p>
              <div data-slate-void='true' data-key='91'>
                <div>
                    <div>
                      <div>
                          <div>
                            <div>
                                <div>
                                  <div>Şifreli Saklama</div>
                                </div>
                                <div>
                                  <div>Parolanız geri d&ouml;nüştürülemez şifreleme metodları ile güvenli bir şekilde saklanmaktadır.</div>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
              <h2>2.2. SOSYAL MEDYA BAĞLANTILARINIZ İLE PAYLAŞTIĞINIZ BİLGİLERİNİZ</h2>
              <p>Sizlerden üçüncü parti servis ve sosyal medya hesaplarınızla kurduğunuz linkler aracılığı ile topladığımız bilgiler servislerimize g&ouml;re farklılık g&ouml;stermekle birlikte aşağıdaki gibidir:</p>
              <ul>
                <li>profil fotoğrafınız</li>
                <li>adınız ve soyadınız,</li>
                <li>cinsiyetiniz ve/veya cinsel tercihleriniz,</li>
                <li>e-posta adresiniz ve/veya telefon numaranız,</li>
                <li>sosyal medya hesabınızın firma tarafından atanan tanımlama numarası,</li>
                <li>fiziksel konumunuz,</li>
                <li>beğenileriniz vb. etkileşim istatistikleriniz ve</li>
                <li>ilgili sosyal platformun sunduğu ve sizin bizimle paylaşımını onayladığınız sizi tanımlamamıza ve deneyiminizi iyileştirmemizde bize yardımcı olabilecek diğer bilgileriniz.</li>
              </ul>
              <h2>2.3. CİHAZ VE TARAYICI üZERİNDEN PAYLAŞTIĞINIZ BİLGİLERİNİZ</h2>
              <p>Sizlerden servislerimize erişmek için kullandığınız cihaz ve tarayıcılardan servislerimize verdiğiniz izinler dahilinde topladığımız bilgiler servislerimize g&ouml;re farklılık g&ouml;stermekle birlikte aşağıdaki gibidir:</p>
              <ul>
                <li>cihaz tanımlama numaranız (IMEI, seri no vb.),</li>
                <li>cihaz yazılım sürümü, cihaz marka ve modeli, ekran boyutu ve diğer teknik bileşenlere ait bilgiler,</li>
                <li>g&ouml;rüntü ve/veya ses kaydınız,</li>
                <li>konumunuz,</li>
                <li>cihazınız ile ilgili dil, b&ouml;lge vb. yerelleştirme bilgileriniz ve</li>
                <li>ilgili cihaz veya tarayıcının sunduğu ve sizin bizimle paylaşımını onayladığınız sizi tanımamıza ve deneyiminizi iyileştirmemizde bize yardımcı olabilecek diğer bilgileriniz.</li>
              </ul>
              <h2>2.4. DİĞER KULLANICILARA AçIK BİLGİLERİNİZ</h2>
              <p>Bu verilerden aşağıda belirtilenler ve aşağıda belirtilen diğer veriler ise servislerimizi kullanan diğer kullanıcı ve oyuncularla paylaşılmak üzere toplanmaktadır. Bu bilgilerin bir kısmını veya tamamını kullandığınız servislerimizin bazılarında g&ouml;re diğer kullanıcıların veya oyuncuların g&ouml;rüntülemesini engelleme şansınız bulunmaktadır.</p>
              <ul>
                <li>kullanıcı fotoğrafınız,</li>
                <li>kullanıcı rumuzunuz,</li>
                <li>cinsiyetiniz veya cinsel tercihiniz,</li>
                <li>yaşınız veya yaş aralığınız,</li>
                <li>fiziksel konumunuz,</li>
                <li>sosyal medya hesaplarınızın linkleri,</li>
                <li>uygulama ve oyun kullanım detaylarınız ve</li>
                <li>Odit kullanıcı tanımlama numaranız.</li>
              </ul>
              <h2>2.5. REHBER BİLGİLERİNİZ</h2>
              <p>Telefon rehberinizdeki kişilerin bilgilerini ilgili cihaz izinlerini verdiğiniz taktirde toplamaktayız. Bu bilgileri servislerimize arkadaşlarınızı çağırma, mevcutta servislerimizi kullanan arkadaşlarınızı tanımlama ve tanıtıcı bilgiler paylaşmak için kullanıyoruz.</p>
              <div data-slate-void='true' data-key='178'>
                <div>
                    <div>
                      <div>
                          <div>
                            <div>
                                <div>
                                  <div>İzinli Kullanım</div>
                                </div>
                                <div>
                                  <div>Telefon rehberinizin bilgilerini sadece ve sadece kullandığınız cihaz üzerinde sizin kendi insiyatifiniz ve aktif izniniz alındığı müddetçe kullanmaktayız.</div>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
              <p>Telefon rehberinizdeki bilgileri bizimle paylaşırken, rehberinize kayıtlı kişilerin gizlilik ile ilgili beklentilerini kollamak ve bu bilgileri bizimle paylaşıp, paylaşmama kararını vermek sizin sorumluluğunuzdadır. Telefon rehberinizdeki kişilerin bilgileriniz bizimle paylaşırken ilgili kişilerden izin almış olmanız ve ilgili kişinin tabii olduğu yerel ve uluslararası kanunlara uygun bir şekilde kişinin verilerini bizimle paylaşmış olduğunuzdan emin olmalısınız.</p>
              <p>Başka kullanıcı veya oyuncular tarafından bizimle paylaşılan rehber bilgileri dahilinde de sizin iletişim bilgilerinize ulaşabiliriz. B&ouml;yle bir durumda, izniniz olmadan verilerinizin sistemimize işlendiğini düşünüyorsanız, bizimle iletişime geçebilir ve bilgilerinizin sistemimizden silinmesini ve bir daha sistemimize dahil edilmemesini talep edebilirsiniz. Talepleriniz 3 gün içerisinde işleme alınacak ve verileriniz 7 gün içerisinde kalıcı olarak sistemimizden silinecektir.</p>
              <p>Kişisel verilerinizin silinmesi talebinizi, talebi yaptığınız günden itibaren 7 gün içerisinde iptal başvurusunda bulunabilirsiniz.</p>
              <h2>2.6. SERVİS KULLANIMI SIRASINDA OLUŞTURDUĞUNUZ BİLGİLERİNİZ</h2>
              <p>Servislerimizi kullanırken, servislerimizle yaptığınız klik, kaydırma, dokunma, sallama, form doldurma ve benzeri tüm etkileşimleri parola, kredi kartı, banka hesap numarası, kimlik numarası vb. gibi yüksek gizlilik gerektiren bilgileriniz saklı kalacak şekilde g&ouml;rsel, işitsel veya metinsel veri olarak kaydedebiliriz.</p>
              <p>Bu bilgiler, servislerimizi nasıl kullandığınız, en çok hangi &ouml;zelliklerden yararlandığınız, servisi ne kadar sıklıkla ve ne kadar sürelerde kullandığınız, hangi ürünleri satın aldığınız vb. konularda kişisel tercihlerinizi anlamak ve kullanıcı deneyiminizi arttırmak için kullanılmaktadır.</p>
              <p>Servislerimizde, uygulama içi para birimi, başarı kıstasları vb. bilgileri ise size &ouml;düllerinizi tespit etmek ve dağıtmak için saklanır.</p>
              <h2>2.6.1. İLETİŞİM ARAçLARI İLE OLUŞTURDUĞUNUZ BİLGİLER</h2>
              <p>Servislerimizde yer alan canlı müşteri destek iletişimi, diğer kullanıcılarla yazılı, sesli veya g&ouml;rüntülü iletişim y&ouml;ntemleri aracılığı ile oluşturduğunuz veriler de tarafımızca kaydedilmektedir.</p>
              <p>Bu bilgiler yasal araştırmalar, kullanıcı şikayetlerinin kontrolü, yasalara uyumluluk vb. kontrolleri sağlamak amacı ile kullanılmaktadır.</p>
              <p>Verilerinizin toplandığı iletişim araçları kullandığınız servislerimize g&ouml;re farklılık g&ouml;stermekle birlikte aşağıdaki araçlardan oluşmaktadır:</p>
              <ul>
                <li>kullanıcı forumları ve ve mesaj listeleri,</li>
                <li>yorum, değerlendirme, zaman tüneli akışı vb. herkese açık paylaşımlarınız,</li>
                <li>destek ekibi iletişiminde kullandığınız tüm yazı, dosya, ses ve g&ouml;rüntü iletişimleri,</li>
                <li>sohbet odası ve bireysel sohbet metinleri, sesleri ve g&ouml;rüntüleri ve</li>
                <li>iletişim araçları içerisinde paylaştığınız dosyalar.</li>
              </ul>
              <p>Bu bilgilere gerçek zamanlı erişebildiğimiz gibi, &ouml;nceden kaydedilmiş arşiv kayıtlarına da erişebiliriz. Bu erişimlerimizin tamamı sizin güvenliğinizi güvence altına alabilmek ve kullanıcı deneyiminizi arttırabilmek için yapmaktayız.</p>
              <p>Odit olarak bu bilgilerin kullanımı ile servislerimizi iyileştirmek, ar-ge çalışmaları yapmak, kullanıcı deneyiminizi kişiselleştirmek ve sizlere ilgi çekici reklamlar sunabilmek amacını güdüyoruz.</p>
              <h2>2.7. FİNANSAL BİLGİLERİNİZ</h2>
              <p>Servislerimizi kullanırken yapacağınız satın alımların aşağıda verilen detayların tamamını kayıt etmekteyiz.</p>
              <ul>
                <li>Sipariş tarih ve saati,</li>
                <li>Satın alma tarih ve saati,</li>
                <li>Sipariş durumu,</li>
                <li>Sipariş numarası,</li>
                <li>Satın alınan ürün kodu, adı, birim fiyatı ve adedi,</li>
                <li>Harcanan bedel ve vergi miktarı,</li>
                <li>Varsa kullandığınız kampanya kodu veya kodları,</li>
                <li>Siparişinize işlenen indirim miktarları,</li>
                <li>Siparişinize eklenen varsa ek bedellerin miktarları,</li>
                <li>&Ouml;deme yaptığınız 3. parti &ouml;deme sisteminin adı, sistemin sunduğu işlem ve/veya fatura numarası,</li>
                <li>Fatura adresi, vergi numarası ve diğer fatura ile ilgili bilgiler,</li>
                <li>Adınız, soyadınız veya kurumsal ünvanınız,</li>
                <li>&Ouml;deme yaptığınız Internet bağlantınızın IP adresi.</li>
              </ul>
              <p>Bunlar dışında bir bilgiyi Odit bünyesinde tutmamaktayız. Kredi kartı numarası ve diğer kredi kartı bilgileri 3. parti &ouml;deme sisteminin sizden istediği diğer kişisel ve finansal verileri sistemlerimizde tutmamaktayız.</p>
              <p>Servislerimiz içerisinde yaptığınız tüm satın alımlar servise g&ouml;re aşağıdaki tahsilat entegrat&ouml;rlerimiz üzerinden yapılmakta olup, satın alımlarınız ilgili 3. partinin kuralları çerçevesinde gerçekleşir, iptal olur ve iade edilir.</p>
              <ul>
                <li>Amazon</li>
                <li>Apple Pay</li>
                <li>Facebook Pay</li>
                <li>Google Pay</li>
                <li>izyco</li>
                <li>MasterPass</li>
                <li>Paypal</li>
                <li>PayU</li>
                <li>Stripe</li>
                <li>Transferwise</li>
                <li>Wirecard</li>
              </ul>
              <p>Satın alımlarla ilgili şartlarımızı g&ouml;rmek için Kullanım Koşulları adlı belgemizin ilgili b&ouml;lümlerine bakabilirsiniz.</p>
              <h2>2.8. çEREZ VE DİĞER SERVİSLERDEN OTOMATİK TOPLANAN VERİLERİNİZ</h2>
              <p>Size ait cihaza &ouml;zel bilgilerinizi 'çerez' denilen metodu kullanarak saklamaktayız. Servislerimizin çerezleri nasıl kullandığı veya çerez ile toplanan verilere dahil olmamak istemeniz durumda ilgili bilgileri nasıl sileceğinizi veya çerez politikamız ile ilgili ayarlarınızı nasıl değiştireceğinizi ilgili servisimizin web sitesi veya uygulaması içerisinde yer alan 'çerez Politikası' aslı metinde bulabilirsiniz.</p>
              <p>çerezler haricinde, 'beacon', 'pixel', saydam gif ve cihaz tanımlama numarası gibi diğer teknolojik imkanları da servislerimizi kullanım alışkanlıklarınızı &ouml;lçmek için kullanabiliriz.</p>
              <p>çerez ve benzeri teknolojilerle iş ortaklarımız, 3. parti entegrat&ouml;rlerimiz veya biz aşağıdaki verilerin bir kısmını veya tamamını analizlerimizde kullanmak için toplamaktayız:</p>
              <ul>
                <li>IP adersiniz,</li>
                <li>Cihaz ve ekran teknik spesifikasyonları,</li>
                <li>Platform ve mağaza bilgileri,</li>
                <li>Tarayıcı ve işletim sistemi bilgileri ve seçili diliniz,</li>
                <li>cihaz tanımlama numaraları (MAC, IMEI, IDFA ve AAID),</li>
                <li>cihazınıza verdiğiniz isim,coğrafi konumunuz,</li>
                <li>telefon numaranız,</li>
                <li>cihazınızda yüklü diğer Odit uygulamalarının ve bağımsız uygulamaların listesi,</li>
                <li>uygulama performans ve hata ayıklama bilgileri,</li>
                <li>giriş ve çıkış ekranları veya adresleri, referans site veya uygulama bilgileri,</li>
                <li>servis kullanımında geçirdiğiniz süre, ekran, sayfa veya &ouml;zellik üzerine yapılan kliklerin sayısı,</li>
                <li>sayfa ve ekran g&ouml;rüntüleme ve açma sayıları,</li>
                <li>alan adları ve</li>
                <li>uygulama veya oyun kullanım durumunuz, etkileşim zamanınız.</li>
              </ul>
              <h2>2.9. DİĞER KAYNAKLARDAN TOPLANAN VERİLERİNİZ</h2>
              <p>İşbu Gizlilik S&ouml;zleşmesi'nde belirtilen kaynakları dışında kaynaklardan, entegrat&ouml;rlerden ve 3. parti servis sağlayıcılarından (telekom operat&ouml;rü, Internet servis sağlayıcısı ve kamu kuruluşları gibi) sizinle ilgili başka bilgiler topluyor olabiliriz. Bu bilgileri kimliğinizi doğrulama, kişisel tercihlerinizi anlama ve size daha iyi bir hizmet verebilmek için kullanıyor olmakla birlikte, bu ve benzeri bilgileriniz hakkında bizimle iletişime geçebilirsiniz.</p>
              <h2>3. VERİ İŞLEME VE KULLANMA Y&Ouml;NTEMLERİMİZ VE YASAL DAYANAKLARIMIZ</h2>
              <p>Sizinle ilgili topladığımız verileri, servislerimizi size &ouml;zel hale getirme, performans ve işlev analizleri yaparak iyileştirme, sizin ve diğer kullanıcıların güvenliğini güvence altına alma, servislerimizin operasyonunu gerçekleştirme, sizinle iletişime geçme, pazarlama ve reklam faaliyetlerini y&ouml;netme ve haklarımızı tanımlama, kollama ve uygulama amacı ile kullanmaktayız.</p>
              <p>Bilgilerinizi yasal gereksinimleri karşılamak, servislerimiz ve kullanımlarından doğan haklarınızı ve haklarımızı korumak ve y&ouml;netmek ve sizin servislerimizi kullanırken bizimle ve diğer kullanıcılar ile yaptığınız paylaşımlar ile ilgili rızanızı almak için kullanmaktayız.</p>
              <p>Bilgilerinizi toplamamızdaki birinci amacımız size sunmayı vaat ettiğimiz servisleri sunabilmek ve bu servisleri sunarken size en iyi kullanıcı deneyimini yaşatabilmektir.</p>
              <p>Bunlara ek olarak bilgilerinizi aşağıdaki amaçları yerine getirmek için de kullanmaktayız:</p>
              <ul>
                <li>kullanıcı ve oyun hesabınızı oluşturarak servislerimizi bütün işlevleri ile kullanmanızı sağlamak,</li>
                <li>diğer Odit kullanıcıları ile bağlantılarınızı tanımlamak ve bu bağlantılar nezdinde size &ouml;zel deneyimler oluşturmak,</li>
                <li>sizinle servislerimizin durumları, güncellemeleri, hizmet kalitemiz, &ouml;zel promosyonlarımız ve ilginizi çekebilecek diğer konular ile ilgili iletişim kurabilmek,</li>
                <li>diğer kullanıcıların ve oyuncuların sizinle iletişime geçmesine olanak sağlayabilmek,</li>
                <li>size gerektiğinde teknik destek sunabilmek,</li>
                <li>güvenliğinizi sağlayabilmek,</li>
                <li>Odit ve bağlı iştiraklerin haklarını savunabilmek,</li>
                <li>Dolandırıcılık, bilgi hırsızlığı ve yasalara aykırı kullanıcı eylemlerine karşı koyabilmek ve gerektiğinde yasal mercilerle uyum içerisinde çalışabilmek,</li>
                <li>Kullanım Şartları dokümanında bildirilen kural ve şartlarımızı uygulayabilmek,</li>
                <li>Yasal gereksinimlere uyabilmek ve yasal taleplere cevap verebilmek,</li>
                <li>3. parti iş ortaklarımızın kullanım s&ouml;zleşmeleri ile uyum sağlayabilmek,</li>
                <li>araştırma, geliştirme ve raporlama faaliyetlerinde bulunabilmek.</li>
              </ul>
              <p>Veri toplama faaliyetlerimiz ve topladığımız veriler tamamen servislerimizi sunabilmemiz ve iyileştirebilmemiz ile ilgili olup, Kullanım Şartları dokümanında belirtilen kıstaslar ile uyumlu olarak ve tamamen yasaların ve sizin izin verdiğiniz çerçevede gerçekleşmektedir.</p>
              <p>Herhangi bir verinizi izin vermediğiniz taktirde toplamayı bırakacağımız gibi, mevcut toplanmış verilerinizin de sistemimizden vakitli bir şekilde silineceğini taahhüt ederiz.</p>
              <p>Yasal durumlar veya talepler çerçevesinde gerçekleşen bazı durumlarda, sizden normal faaliyetlerimizde kullanmayacağımız bazı verileri de talep edebiliriz. Bu taleplerimiz resmi kanallardan ve resmi kararlar ile birlikte (mahkeme kararı vb.) tarafınızdan talep edilecektir.</p>
              <h2>4. VERİ PAYLAŞIMI YAPTIĞIMIZ KİŞİ VE KURUMLAR VE PAYLAŞMA NEDENLERİMİZ</h2>
              <p>Aşağıda belirtilen şartlar, kişiler ve kurumlarla servislerimizi kullandığınız müddetçe sizden topladığımız bilgileri paylaşmaktayız.</p>
              <p>Bazı durumlarda 3. parti uygulamalar ile bilgi paylaşmak yerine, bu uygulamaların sizden doğrudan bilgi toplamalarına olanak verebiliriz. Bu ve benzeri durumlarda ilgili servis sağlayıcıların kendi gizlilik s&ouml;zleşmeleri ve kullanım şartları geçerlilik kazanmakla birlikte işbu s&ouml;zleşme ve Kullanım Şartları s&ouml;zleşmesinde yer alan hükümler doğrultusunda tarafınıza &ouml;nceden bilgilendirme yapılıp, tarafınızdan onay alınmış olarak bu paylaşımlar gerçekleşmektedir.</p>
              <h2>4.1. ARKADAŞLARINIZ VE DİĞER KULLANICILAR / OYUNCULAR</h2>
              <p>Odit uygulama ve oyunları sizi ve diğer kullanıcı ve oyunları birbirinizi tanımasanız bile sıklıkla birbirinizle etkileşime girmeye teşvik edecek şekilde kurgulanmıştır. Eğer servislerimize Facebook, Twitter, Google gibi bir sosyal medya entegrasyonu ile giriş yapıyorsanız, bu platformlarda arkadaş olarak tanımladığınız kişiler ve hesaplar aynı serviste sizin çevrimiçi durumunuzu, profil fotoğrafınzı, kullanıcı adınızı ve servis kullanımı ile ilgili istatistikleriniz ile yukarıda belirtilen diğer başka bilgilerinizi g&ouml;rebilir, sizinle iletişime veya etkileşime geçebilir.</p>
              <p>Diğer platformlarınızda arkadaşınız olmasalar bile, servislerimizi kullanan diğer kullanıcılar kullanıcı adınız, profil fotoğrafınız ve puan durumu vb. servis kullanım istatistiklerinizi g&ouml;rebilir, ve size oyun eşleşmeleri esnasında mesaj g&ouml;nderebilir.</p>
              <h2>4.2. DİĞER RAVİOSOFT İŞTİRAKLERİ VE SERVİSLERİ</h2>
              <p>Bilgilerinizi tüm grup şirketlerimizle, bu bilgilerinizi bir servisten elde ettiğiniz başarı ve kazanımları farklı bir serviste &ouml;düle çevirme, servisler arası promosyonlara dahil olma vb. amaçlarla paylaşmaktayız.</p>
              <h2>4.3. üçüNCü PARTİ SERVİSLER</h2>
              <p>&Ouml;deme sistemleri, reklam verenler, hata ayıklama ve servis kullanım davranışları raporlama ile ilgili servis sağlayıcılarımızla, sadece ilgili servis sağlayıcısını ilgilendiren verilerinizi paylaşmaktayız.</p>
              <p>Reklamlar sayesinde sistem ve geliştirme maliyetlerimizin bir kısmını karşılayarak ücretsiz servislerimizi size sunabiliyoruz.</p>
              <p>Hata ayıklama ve kullanmı davranışları raporlama servisleri ile uygulama ve oyunlarımızı size &ouml;zel kişiselleştirmeler ile size sunarken, deneyiminizi hatasız bir şekilde gerçekleştirme ve iyileştirme şansını elde ediyoruz.</p>
              <p>&Ouml;deme sistemleri entegrasyonu ile servislerimiz ile ilgili satın alabileceğiniz ürünlerin &ouml;demelerini tahsil edip, tarafınıza fatura g&ouml;nderimi yapabiliyoruz.</p>
              <p>Hiçbir üçüncü parti ile işlevsel gereksinimler dışında, üçüncü partilerin kendilerinin promosyonları veya benzeri ihtiyaçları için sizin izniniz olmadan kişisel verilerinizi paylaşmıyoruz.</p>
              <h3>4.3.1. REKLAM VERENLERLE PAYLAŞILAN VERİLER</h3>
              <p>Sizlere sunulan reklamların ilgi alanıza y&ouml;nelik reklamlar olması ve getiri performanslarının kabul edilebilir oranlarda olması için reklam verenlerle aşağıdaki verilerinizi paylaşıyoruz:</p>
              <ul>
                <li>reklam klik sayıları ve perfomansı,</li>
                <li>IP adresi, cihaz tanımlama numaraları ve diğer teknik cihaz bilgileri,</li>
                <li>Odit tanımlama numaranız ve</li>
                <li>diğer oyun ve uygulama kullanım bilgileriniz.</li>
              </ul>
              <p>Reklam verenlerimiz Odit oyun ve uygulamalarında yer alan reklamları ile ilgili etkilişim bilgilerinizi bir veya daha fazla takip teknolojisi kullanarak toplayabilmektedirler. Ek olarak, bir reklama tıklayıp farklı bir site veya uygulamaya y&ouml;nlendirildiğinizde Odit oyun ve uygulamasından çıkarak reklam verenin veya reklam sahibi kişi veya kuruluşun web sitesine veya uygulamasına erişmiş olacaksınız. Bu durumda ilgili web sitesi veya uygulamada yer alan Gizlilik Şartları ve Kullanım Şartları başta olmak üzere diğer s&ouml;zleşmelere tabi olursunuz. Odit'un dahil olmadığı sizin ve ilgili reklam veren veya reklam sahibi kişi veya kuruluş ile doğrudan bilgi paylaşımı yapıyor olacağınızdan dolayı, Odit uygulama veya web sitelerinden 3. parti uygulama veya web sitelerinize geçtiğiniz taktirde buralarda yaşayacağınız problemlerle ilgili Odit sorumluluk kabul etmeyecektir..</p>
              <h2>4.4. KAMU KURUMLARI VE YASAL MERCİLER</h2>
              <p>Odit olarak yerel yasalara bağlılığımız baki olup, bu yasalara uygun hareket etmek durumundayız. Bu sebeple ikamet ettiğiniz yerel yasalar doğrultusunda depoladığımız kişisel verilerinizi (uygulama ve oyun kullanım detaylarınız dahil olmak üzere)</p>
              <ul>
                <li>Yasa ve y&ouml;netmeliklere uygunluk için,</li>
                <li>Kamu kuruluşları ve mahkemelerin yasal talepleri çerçevesinde yargıya yardımcı olmak amacı ile,</li>
                <li>Ulusal ve uluslararası güvenliği, insan veya hayvan sağlığı ve hayatını tehdit ettiği, doğaya zarar verdiği tespit edilen davranışlarda şikayet üzerine veya firma içi araştırma başlatmak sureti ile yasal mercilerden talep gelmese dahi, yasal mercilerle paylaşmak için ve</li>
                <li>Odit ile iş ortaklarınının haklarını korumak amacı ile</li>
              </ul>
              <p>mahkeme ve benzeri yasal kurumlarlarla paylaşabiliriz.</p>
              <h2>4.5. FİRMA SATIŞI VEYA BİRLEŞMELERİ</h2>
              <p>Odit kurumsal yapısındaki birleşme, ayrılma, kısmi veya tam satış, iş ortaklarının güncellenmesi gibi tüm değişikliklerde bilgilerinizi uygulama veya oyunlarımızın yeni hak sahiplerine, siz aksini talep etmediğiniz sürece devretmekteyiz.</p>
              <h2>5. YASAL HAKLARINIZ VE VERİLERİNİZİ G&Ouml;RüNTüLEME VE Y&Ouml;NETME İMKANLARINIZ</h2>
              <p>Dünyanın neresinde ikamet ediyor olursanız olun verilerinizin kontrolü tamamen sizde olup, aşağıdaki haklarınız bakidir:</p>
              <ul>
                <li>Tarafımızdan saklanan kişisel verilerinizin tamamının d&ouml;kümünü almayı istediğiniz zaman talep edebilirsiniz,</li>
                <li>Tarafımızdan saklanan kişisel verilerinizin tamamının veya bir kısmının silinmesini ve ileriki süreçlerde kişisel verilerinizin tamamının veya bir kısmının takip dışı bırakılmasını istediğiniz zaman talep edebilirsiniz,</li>
                <li>Pazarlama ve reklam iletişimlerimizden istediğiniz zaman çıkma talebinde bulunabilirsiniz,</li>
                <li>İzniniz dahilinde topladığımız veriler için izin vermeyi istediğiniz zaman kesebilirsiniz, ancak &ouml;nceden işlenmiş verilerinizin kaldırılması için verilerinizin silinmesi talebinde ayrıca bulunmanız gerekmektedir,</li>
                <li>Veri güvenlik yetkilimiz ile istediğiniz zaman iletişime geçebilir ve şikayetlerinizi iletebilirsiniz</li>
              </ul>
              <h2>5.1. VERİ SİLME</h2>
              <p>Mevcut verilerinizi silmek için ilgili uygulama veya oyunun detaylarında belirtilen adımları uygulamanız gerekmektedir.</p>
              <p>Verilerinizin silinmesi için uygulama veya oyunlara erişerek teker teker işlem yapmak istemiyorsanız Veri Silme Talep Formu'nu doldurabilirsiniz.</p>
              <p>Talebinizi ilettikten kısa bir süre sonra verileriniz sistemimizden silinecektir; ancak, fatura bilgileriniz ve bazı bilgileriniz yasal sebeplerden &ouml;türü haklarımızı savunabilmek ve yasalara uyum sağlayabilmek için verinin niteliğine g&ouml;re tamamen silinmeden &ouml;nce belli bir süre (10 seneye kadar) arşivlerimizde tutulmaya devam edilebilir.</p>
              <p>Ayrıca, hali hazırda yasal mercilerin verilerinizin incellenmesi için yine yasal yollarla sistemimizde kayıtlı verilerinize şerh koymuş olmaları halinde verileriniz, koyulan şerh kaldırılana kadar sistemimizde tutulmaya devam eder.</p>
              <h3>5.1.1. ADAM ASMACA VERİ SİLME</h3>
              <p>Bilgi Savaşı oyununda kullandığınız kanala g&ouml;re verilerinizi farklı şekillerde silme talebinde bulunabilirsiniz.</p>
              <p>BiP Messenger</p>
              <ol>
                <li>Sohbet ekranında 'menü' yazıp mesajı g&ouml;nderin,</li>
                <li>Açılan 'Ana Menü' seçenek listesinde 'Profil' seçeneğine tıklayın,</li>
                <li>Uygulama içi tarayıcıda açılan 'Profil' ekranı üstünde yer alan 'Hesabımı Kapat' butonuna tıklayın,</li>
                <li>'Hesabınızın kapatılarak tüm verilerinizin silinmesini onaylıyor musunuz?' yazılı ekranda 'Evet' seçeneğini seçin.</li>
              </ol>
              <p>Mobil Uygulama</p>
              <ol>
                <li>Mobil uygulama 'Ana Ekranı'nın sol üstünde yer alan ve üç çizgiden oluşan menü ikonuna tıklayın.</li>
                <li>Açılan menüde 'Hesabım' seçeneğini tıklayın.</li>
                <li>Açılan ekranda 'Hesabımı Kapat' butonuna tıklayın,</li>
                <li>'Hesabınızın kapatılarak tüm verilerinizin silinmesini onaylıyor musunuz?' yazılı ekranda 'Evet' seçeneğini seçin.</li>
              </ol>
              <p>Web Uygulama</p>
              <ol>
                <li>Webuygulama 'Ana Ekranı'nın sol üstünde yer alan ve üç çizgiden oluşan menü ikonuna tıklayın.</li>
                <li>Açılan menüde 'Hesabım' seçeneğini tıklayın.</li>
                <li>Açılan ekranda 'Hesabımı Kapat' butonuna tıklayın,</li>
                <li>'Hesabınızın kapatılarak tüm verilerinizin silinmesini onaylıyor musunuz?' yazılı ekranda 'Evet' seçeneğini seçin.</li>
              </ol>
              <h3>5.1.2. DUNDRA VERİ SİLME</h3>
              <p>Dundra uygulamasında kullandığınız kanala g&ouml;re verilerinizi farklı şekillerde silme talebinde bulunabilirsiniz.</p>
              <p>BiP Messenger</p>
              <ol>
                <li>Sohbet ekranında 'menü' yazıp mesajı g&ouml;nderin,</li>
                <li>Açılan 'Ana Menü' seçenek listesinde 'Profil' seçeneğine tıklayın,</li>
                <li>Uygulama içi tarayıcıda açılan 'Profil' ekranı üstünde yer alan 'Hesabımı Kapat' butonuna tıklayın,</li>
                <li>'Hesabınızın kapatılarak tüm verilerinizin silinmesini onaylıyor musunuz?' yazılı ekranda 'Evet' seçeneğini seçin.</li>
              </ol>
              <p>Facebook Messenger</p>
              <ol>
                <li>Sohbet ekranında 'menü' yazıp mesajı g&ouml;nderin,</li>
                <li>Açılan 'Ana Menü' seçenek listesinde 'Profil' seçeneğine tıklayın,</li>
                <li>Uygulama içi tarayıcıda açılan 'Profil' ekranı üstünde yer alan 'Hesabımı Kapat' butonuna tıklayın,</li>
                <li>'Hesabınızın kapatılarak tüm verilerinizin silinmesini onaylıyor musunuz?' yazılı ekranda 'Evet'</li>
                <li>seçeneğini seçin.</li>
              </ol>
              <h2>5.2. üçüNCü PARTİ UYGULAMALARDA VERİ SİLME</h2>
              <p>Google, Facebook ve benzeri üçüncü parti uygulamalar üzerinden hakkınızda topladığımız verileri veya üçüncü partilerin bizim üzerimizden hakkınızda topladıkları verileri, ilgili üçüncü parti ile iletişime geçerek silme talebinde bulunabilirsiniz.</p>
              <p>Mobil uygulama veya oyunlarda cihaz ile olan veri alışverişimizi cihazınızın 'ayarlar' b&ouml;lümünde ilgili alanlara erişerek y&ouml;netebilirsiniz.</p>
              <h2>5.3. PAZARLAMA İLETİŞİMLERİNİ DURDURMA</h2>
              <p>Pazarlama iletişimlerini kullandığınız servis ve iletişimin yapıldığı kanala g&ouml;re farklı şekillerde y&ouml;netebilirsiniz.</p>
              <h3>5.3.1. E-POSTA İLETİŞİMLERİ</h3>
              <p>Tüm servisler için e-posta üzerinden yapılan tüm tanıtım ve pazarlama iletişimlerini e-posta içerisinde bulunan 'Listeden Ayrıl' linkine tıklayarak durdurabilirsiniz.</p>
              <h3>5.3.2. SMS VE SESLİ ARAMA</h3>
              <p>SMS ve sesli arama y&ouml;ntemi ile yapılan tüm tanıtım ve pazarlama iletişimlerini ilgili servisimizin 'ayarlar' b&ouml;lümü altında yer alan 'iletişim ayarları' alanından y&ouml;netebilirsiniz.</p>
              <h3>5.3.3. PUSH BİLGİLENDİRMELERİ</h3>
              <p>Mobil cihaz veya web tarayıcınız üzerinden sevislerimiz ile ilgili aldığınız tüm tanıtım ve pazarlama iletişimlerini sonlandırmak için mobil cihazınızın veya web tarayıcınızın 'push bilgilendirme' ayarları ekranından daha &ouml;nceden vermiş olduğunuz izinleri kaldırarak durdurabilirsiniz.</p>
              <h2>5.4. SERVİS BİLGİLENDİRME İLETİŞİMLERİNİ DURDURMA</h2>
              <p>Aruza, güncelleme vb. servis bilgilendirme iletişimlerini almak istemiyorsanız hesabınızı kapatmanız gerekmektedir.</p>
              <h2>6. çOCUKLARLA İLGİLİ POLİTİKALARIMIZ</h2>
              <p>Genel manasıyla servislerimiz 16 yaşından küçük çocukları hedef alan servislerden oluşmamaktadır. Servislerimizden yararlanmak için kullanıcılarımızın 16 yaşından büyük olmaları gerekmektedir, ve 16 yaşından küçük kullanıcıların servislerimizi kullanmalarına izin vermediğimiz gibi 16 yaşından küçük kullanıcıların kişisel verilerini bilinçli olarak toplamamaktayız. Ancak, kullanıcı sistemi yanıltıcı bilgiler vererek, yaş sınırlarımıza uymadan kayıt ekranlarını geçmeleri halinde istemdışı bir şekilde bu kullanıcıların verilerini toplayabiliriz. B&ouml;yle bir durumun tespiti halinde, ilgili kullanıcının tüm verilerini sileceğimizi ve kullanıcının servislerimizi kullanma hakkını kaldıracağımızı beyan ederiz.</p>
              <h2>7. VERİ SAKLAMA Y&Ouml;NTEMLERİMİZ VE SüRELERİMİZ</h2>
              <p>Servislerimizde topladığımız veriler, servise ve kullanıcının konumuna g&ouml;re farklı veri merkezlerinde tutulmaktadır.</p>
              <p>Dünyanın neresinde ve ne amaçla olursa olsun, verilerimiz güvenli ortamlarda ve birinci sınıf bulut hizmet sağlayıcılarında yer alan sunucu ve veri tabanlarımızda tutulmaktadır.</p>
              <h2>7.1. VERİ MERKEZLERİMİZ</h2>
              <p>Kişisel verileriniz konumunuza g&ouml;re aşağıdaki konum ve servis sağlayıcılarda yer alan sunucu ve veri tabanlarımızda tutulmaktadır:</p>
              <ul>
                <li>Almanya, Amazon Web Services</li>
                <li>Almanya, Digital Ocean</li>
                <li>Almanya, Microsoft Azure</li>
                <li>A.B.D., Amazon Web Services</li>
                <li>A.B.D., Microsoft Azure</li>
                <li>Singapur, Digital Ocean</li>
                <li>Türkiye, Radore</li>
              </ul>
              <h2>7.2. VERİ SAKLAMA SüRELERİMİZ</h2>
              <p>Veri saklama sürelerimiz verinin niteliğine ve veriyi kullanım şeklimize g&ouml;re değişiklik g&ouml;stermektedir. Kişisel verilerinizi yasal çerçevelerde ve gereğinden uzun süreyle saklamamaktayız. &Ouml;rneğin hesabınızı kapattıktan sonra verileriniz verileriniz üzerinde herhangi bir yasal şerh veya inceleme talebi yoksa anında silinir, şu kadar ki;</p>
              <ul>
                <li>Yaptığınız &ouml;demeler, tarafınıza g&ouml;nderilen faturalar vb. muhasebe ve vergi hukuku ile doğrudan ilgili bilgileriniz 10 seneye kadar,</li>
                <li>Uygulama ve oyun kullanım istatistikleriniz ve davranış bilgileriniz servisimize g&ouml;re değişiklik g&ouml;stermekle beraber 48 aya kadar,</li>
                <li>Oyunlarımızda yer alan skorboard vb. verileriniz 3 aya kadar,</li>
                <li>İsim, soyad, telefon ve e-posta gibi kişisel verileriniz ve iletişim verileriniz, hesabınız silinene kadar</li>
              </ul>
              <p>sunucularımızda saklanmaktadır.</p>
              <p>Kredi kartı vb. bilgileriniz asla tarafımızca saklanmamakta olup, kart saklama yetkisi tanımladığınız müddetçe aşağıdaki &ouml;deme servis sağlayıcılarımız tarafından tutulabilir:</p>
              <ul>
                <li>Amazon Pay</li>
                <li>Apple Pay</li>
                <li>Google Pay</li>
                <li>izyco</li>
                <li>Stripe</li>
                <li>Paypal</li>
              </ul>
              <p>Bunlarla ilgili daha fazla bilgiyi ilgili servis sağlayıcıdan talep edebilirsiniz.</p>
              <h2>8. BİLGİLERİNİZİN GüVENLİĞİ</h2>
              <p>Bilgilerinizin güvenliği için gerekli tüm &ouml;nlemleri almaktayız. Bu &ouml;nlemler veri türü ve içeriğine g&ouml;re değişiklik g&ouml;sterebilir.</p>
              <p>Ancak tüm sistemlerimiz SSL güvenlik sertifikaları ile haberleşme sağlayıp, güvenlik duvarları ile korunmaktadır</p>
              <p>Ayrıca,</p>
              <ul>
                <li>Parola bilgileriniz geri d&ouml;nüştürülemez şekilde şifrelenir ve</li>
                <li>Hesap erişimi için iki adımlı doğrulama y&ouml;ntemleri kullanılır.</li>
              </ul>
              <h2>9. POLİTİKA GüNCELLEMELERİ VE BİLGİLENDİRMELER</h2>
              <p>İşbu belgede yer alan koşulları gereğini g&ouml;rdüğümüz an değiştirme hakkını saklı tutmaktayız. İşbu belge güncellendiğinde 'Son Güncelleme' tarihi de güncellenecek ve tüm kullanıcılarımız e-posta ile bilgilinedirilecektir.</p>
              <h2>10. İLETİŞİM</h2>
              <div>
                <div>
                    <div>
                      <table>
                          <tbody>
                            <tr>
                                <td>
                                  <div>
                                      <p>Gizlilik ve Veri Y&ouml;netimi</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                      <p>privacy.policy@Odit.com</p>
                                  </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                  <div>
                                      <p>Veri Koruma G&ouml;revlisi</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                      <p>data.protection@Odit.com</p>
                                  </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                  <div>
                                      <p>Hukuk Departmanı</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                      <p>legal@Odit.com</p>
                                  </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                  <div>
                                      <p>Veri Silme Talep Formu</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                      <p>https://data-protection.Odit.com/forms/request-for-data-deletion</p>
                                  </div>
                                </td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                </div>
              </div>
              <p>&nbsp;</p>
          </div>
        </div>

        <hr>

        <router-link :to="'/terms-of-use?playerId=' + this.$route.query.playerId">Kullanım Koşulları</router-link>
        <router-link :to="'/guide?playerId=' + this.$route.query.playerId">Yardım</router-link>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      content: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.table {
  border: 1px solid #C4C4C4;
  text-align: left;

  tr {
    td, th {
      border-bottom: 1px solid #C4C4C4;
      border-right: 1px solid #C4C4C4;
      padding: 5px 15px;
    }  
  }
}
</style>
