<template>
  <div class="home">
    <div class="flex flex-col items-center justify-center p-8 guide">
        <h2>Kullanım Koşulları</h2>
        <div class='gizlilik'>
            <div>
                <div>
                    <div>
                        <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                    <div>
                                        <p><strong>Yürürlüğe Girdiği Tarih:</strong></p>
                                    </div>
                                    </td>
                                    <td>
                                    <div>
                                        <p>05.11.2020</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div>
                                        <p><strong>Son G&uuml;ncelleme Tarihi:</strong></p>
                                    </div>
                                    </td>
                                    <td>
                                    <div>
                                        <p>05.11.2020</p>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </div>
                <p>'Kullanım Şartları' olarak adlandırılan işbu s&ouml;zleşme sizin, Emniyetevleri, Yamaç Sk. No:1, 34415 Kâğıthane/İstanbul adresine mukim Türkiye menşeili Odit Teknoloji A.Ş. firması ile (bundan sonra Odit olarak anılacaktır) olan ilişkinizi y&ouml;netmek ve Odit uygulama, oyun ve hizmetleri ile bunlara ait web siteleri ve diğer dijital ortamların (bundan sonra Servis olarak anılacaktır) kullanım şartlarını belirlemek amacı ile hazırlanmıştır.</p>
                <p>Herhangi bir hizmetimizden faydalanmadan, uygulama veya oyunumuzu cihazınıza y&uuml;kleyerek a&ccedil;madan ve web sitelerimizi gezmeden &ouml;nce 'Kullanım Şartları' ve 'Gizlilik S&ouml;zleşmesi' isimli s&ouml;zleşmelerimizi kabul etmiş olmanız gerekmektedir.</p>
                <p>Hizmet, uygulama ve oyunlarımızdan yararlanmak i&ccedil;in belirtilen s&ouml;zleşmeleri kabul etmenizin yanı sıra, hizmet, uygulama veya oyuna g&ouml;re farklılık g&ouml;stermekle birlikte sistemlerimize kayıt olarak bir 'hesap' oluşturmanız gerekebilir.</p>
                <p>Hizmet, uygulama ve oyunlarımızı kullanmak i&ccedil;in hesap oluşturmanız veya hesap oluşturmasanız dahi hizmet, uygulama ve oyunlarımızı kullanmaya başlamanız halinde yaşınızın ikamet ettiğiniz &uuml;lkenin yasalarına g&ouml;re reşit olduğunu ve işbu s&ouml;zleşmeyi ve Gizlilik S&ouml;zleşmesi'ni peşin olarak kabul etmiş sayılırsınız.</p>
                <p>Eğer ikamet ettiğiniz &uuml;lkenin yasalarına g&ouml;re reşit sayılmıyorsanız, yasal vasinizin 'Kullanım Şartları' ve 'Gizlilik S&ouml;zleşmesi'ni okuyarak kabul etmesi gerekmektedir.</p>
                <p>Hizmet, uygulama ve oyunlarımıza erişirken Facebook, Apple GameCenter, Google GameCenter, Google+ ve benzeri sosyal medya hesaplarınızı kullanıyorsanız, hizmet, uygulama ve oyunlarımıza eriştiğiniz anda ilgili firmanın şartlarını da kabul etmiş sayılırsınız.</p>
                <p>Odit, işbu s&ouml;zleşmenin ve bağlı diğer s&ouml;zleşme ve eklerinin ihtiyacı doğrultusunda tamamının veya bazı b&ouml;l&uuml;mlerinin d&uuml;zenlenmesi hakkını, d&uuml;zenleme yapıldığı anda resmi duyuru y&ouml;ntemi olarak belirlediği mecralarda duyurmak kaydı ile saklı tutar.</p>
                <p>Eğer herhangi bir zaman, s&ouml;zleşme şartlarını kabul etmediğinizi beyan etme ihtiyacı duyarsanız hizmeti uygulama ve oyunlarımızı kullanmayı derhal durdurmanız gerekir.</p>
                <h2 id='1.-lisans'>1. LİSANS</h2>
                <h4 id='1.1.-sinirli-servis-kullanim-lisansi'>1.1. Sınırlı Servis Kullanım Lisansı</h4>
                <p>İşbu 'Kullanım Şartları' s&ouml;zleşmesi, 'Gizlilik S&ouml;zleşmesi' ve diğer tamamlayıcı s&ouml;zleşmeler ve ekleri kabul ettiğiniz s&uuml;rece, Odit size servislerini genel kullanım şekli ile haklarınızı transfer etmeksizin veya alt lisanslama yapmaksızın ve haklarınızdan vazge&ccedil;meksizin servislerine erişim ve kullanım hakkı sağlar.</p>
                <p>Bu lisans ile servislerimize sadece ticari olmayan ama&ccedil;larla, kendi gayri finansal ihtiya&ccedil;larınızı gidermek ve kendinizi eğlendirmek amacı ile limitli bir şekilde kullanabilirsiniz.</p>
                <p>Servis kullanımınıza ilişkin diğer kısıtlamalar aşağıda belirtilmiştir:</p>
                <ul>
                    <li>Reşit olmayan kişiler tarafından servislerimizin sizin cihazınız &uuml;zerinden kullanılması durumunda doğacak t&uuml;m zararlarda mesuliyetin sizde olduğunu kabul edersiniz,</li>
                    <li>Kredi kartı vb. &ouml;deme ara&ccedil;larınızın (&ccedil;ocuğunuz vb.) reşit olmayan kişiler tarafından kullanılması halinde yaşanacak t&uuml;m zararlarda mesuliyetin sizde olduğunu kabul edersiniz,</li>
                    <li>Hesabınızı başka kişilere satmak, kiralama veya &uuml;cretsiz devretmek usul&uuml; ile aktaramazsınız,</li>
                    <li>Başka kişilerin hesaplarını satın alamaz, kiralayamaz veya &uuml;cretsiz devralamazsınız,</li>
                    <li>Sahte kimlik bilgileri ile hesap oluşturamazsınız veya başka birinin kimliğini kullanarak hesap oluşturamazsınız,</li>
                    <li>Odit tarafından herhangi bir serviste yaptığınız s&ouml;zleşme şartlarına uygun olmayan davranışınızdan &ouml;t&uuml;r&uuml; hesabınız yasaklandığı taktirde diğer servislerde de hesap a&ccedil;amazsınız,</li>
                    <li>Hesabınızı ticari ama&ccedil;larla kullanamazsınız; reklam, sahtekarlık, ideoloji yaymak, inan&ccedil; propogandası yapmak, misyonerlik faaliyetlerinde bulunamazsınız ve yasalara aykırı diğer propogandalarda bulunamazsınız.</li>
                </ul>
                <h4 id='1.1.1.-hesap-kullanimi-ve-oturum-acma-bilgileri'>1.1.1. Hesap Kullanımı ve Oturum A&ccedil;ma Bilgileri</h4>
                <p>Servislerimizi kullanmanız i&ccedil;in servislerimize kayıt olarak hesap oluşturmanız gerekmektedir. Hesabınızda g&uuml;venli bir şekilde oturum a&ccedil;abilmek i&ccedil;in ise sizden parola se&ccedil;meniz veya e-posta adresiniz ve mobil telefon numaranız gibi ikincil bir y&ouml;ntem ile ge&ccedil;ici şifre edinebilmeniz gereklidir. Hesap ve Oturum A&ccedil;ma Bilgileri'ni başkası ile paylaşmamalı ve başkasının hesap ve oturum bilgilerini kullanarak sisteme erişmemelisiniz.</p>
                <p>Bunlarla sınırlı olmamak kaydı ile hesap ve oturum bilgilerinizi paylaşmamalı ve bu bilgilerin kaybolmaması ve &ccedil;alınmaması i&ccedil;in gerekli &ouml;nlemleri almalısınız. Bilgilerinizin paylaşılması, kaybolması veya &ccedil;alınması da dahil olmak &uuml;zere bir g&uuml;venlik ihlalinin farkına varmanız durumunda veya b&ouml;yle bir ihlalden makul seviyede kuşku duymanız durumunda durumu derhal Odit'e bildirmeli ve Oturum A&ccedil;ma Bilgileri'nizi değiştirmelisiniz.</p>
                <p>Oturum A&ccedil;ma Bilgileriniz'in gizliliğinden ve g&uuml;venliğinden sorumlu olan tek kişinin kendiniz olduğunu ve tarafınızdan yetki verilmiş olsun ya da olmasın satın almalar dahil hesabınızın kullanımı ve kullanımı sırasında yapılan t&uuml;m g&ouml;rsel ve yazılı paylaşımlar ve sisteme hesabınız tarafından girilen veriler ile sistemden hesabınız tarafından &ccedil;ıkarılan verilerden doğan zararlarda da sorumluluk size aittir.</p>
                <p>Hesap oluştururken sizi servislerimizi kullanırken tanımlamamıza yarayacak ger&ccedil;ek kimliğinizi i&ccedil;eren veya gizleyen bir kullanıcı adı se&ccedil;ebilirsiniz. Bu kullanıcı adının herhangi bir yasaya aykırı olmaması, telif hakkı &ccedil;iğnememesi, 3. şahısların &ouml;zel bilgilerini i&ccedil;ermemesi ve 3. şahısların hakkında haklı ya da haksız ithamlar i&ccedil;ermemesi gerekmektedir.</p>
                <p>Kullanıcı adınızın i&ccedil;erdiği anlamlardan &ouml;t&uuml;r&uuml; doğacak zararlardan kullanıcı adının sahibi olarak sorumlu olacaksınız. Kullanıcı adınızın yukarıdaki yukarıda belirtilen şartlara tabi olması halinde, Odit kullanıcı adınızı değiştirmek veya hesabınızın servislerimize erişimini yasaklamak başta olmak &uuml;zere farklı yaptırımlar uygulama hakkına sahiptir.</p>
                <p>Her bir hizmet, uygulama veya oyun i&ccedil;in tek bir hesap a&ccedil;abilirsiniz. El verdiği s&uuml;rece aynı hesapla, birden farklı servise de erişim sağlayabilirsiniz.</p>
                <h4 id='1.1.2-lisans-kisitlamalari'>1.1.2 Lisans Kısıtlamaları</h4>
                <p>Size sunulan lisans ve kullanım hakkı bazı kısıtlamalara tabidir. Bu kısıtlamalara uygun olmayan t&uuml;m kullanımlarınız hesabınızın yasaklanması ve servislere erişiminizin engellenmesi dışında gereği g&ouml;r&uuml;ld&uuml;ğ&uuml; taktirde hakkınızda hukuki işlem başlatılmasına sebep olabilir.</p>
                <ul>
                    <li>Odit'in servisleri sunma amacı ile ithilafta olacak herhangi bir şekilde servislerimiz ile etkileşim i&ccedil;ine girmemeyi ve destek hizmetlerimizden amacı dışında faydalanmamayı,</li>
                    <li>İsteyerek veya istem dışı bir şekilde bot, hack, em&uuml;lat&ouml;r ve otomasyon ara&ccedil;ları, hile ve benzeri y&ouml;ntemlerle oyun i&ccedil;i kazanımlarınızı ve deneyimlerinizi geliştirmemeyi,</li>
                    <li>Servislerimize ait herhangi bir dosya veya kodu izinsiz bir şekilde d&uuml;zenlememeyi ve dağıtmamayı,</li>
                    <li>Servislerimizin akışını engelleyecek şekilde veya diğer kullanıcı ve oyuncuların servislerimize erişimini engelleyecek veya kısıtlayacak şekilde servislerimizle ve servislerimize ait sunucularla etkileşime ge&ccedil;meyeceğinizi ve saldırıda bulunmayacağınızı</li>
                    <li>Sıralamalarda manip&uuml;lasyon yapmayacağınızı veya hesabnızı satmak veya kiralamak gibi ticari işmlemlerde bulunmayacağınızı,</li>
                    <li>Servislerde yaşanan arızaları ticari ama&ccedil;lar ve servis i&ccedil;i stat&uuml;n&uuml;z&uuml; geliştirmeye veya &ccedil;eşitli manip&uuml;lasyonları yapmak i&ccedil;in kullanmamayı,</li>
                    <li>Bilgisayar vir&uuml;s&uuml;, trafik engelleme vb. saldırıları yapanlarla iş birliği i&ccedil;erisine girmeyeceğinizi,</li>
                    <li>Başkalarının hesaplarını &ccedil;alarak, servislerimizde bulabileceğiniz a&ccedil;ıklardan yararlanarak, kişilerin sistemlerine sızarak veya servislerimizin sistemlerine sızarak, veya ağ iletişimini dinleyerek servislerimize izinsiz erişmek, yasal olmayan yollardan bilgi edinmek ve bu bilgileri sızdırmak gibi faaliyetlerde bulunmayacağınızı,</li>
                    <li>Pornografik i&ccedil;erikler, şiddet ve şidded eğilimi i&ccedil;eren i&ccedil;erikler, ırk&ouml;ılık, ter&ouml;r vb. propoganda i&ccedil;erikleri, madde kullanımı vb. yasal olmayan i&ccedil;erikleri, misyonerlik faaliyeti i&ccedil;eren veya ideoloji yayma ama&ccedil;lı i&ccedil;erikleri servislerimizi kullanarak paylaşmayacağınızı ve yaymayacağınızı,</li>
                    <li>Telif hakları, entellekt&uuml;el haklar, patent ve benzeri hakları &ccedil;iğneyecek paylaşımlarda bulunmayacağınızı,</li>
                    <li>Toplumca tanınmış veya tanınmamış, Odit &ccedil;alışanı olsun veya olmasın herhangi bir kişinin kimliğine b&uuml;r&uuml;nme vb. sahte kişilikler oluşturmamayı,</li>
                    <li>Servislerimizi kullanırken etkileşime ge&ccedil;ebileceğiniz başka kullanıcılara saldırgan veya cinsel, fiziksel, fikirsel hi&ccedil;bir şekilde baskı uygulamayacağınızı, kişiler ve kurumlara karşı k&uuml;&ccedil;&uuml;k d&uuml;ş&uuml;r&uuml;c&uuml;, ve karalayıcı paylaşımlarda bulunmayacağınızı,</li>
                    <li>Servislerimize ait kodları tersine m&uuml;hendislik, şifre kırma vb. y&ouml;ntemlerle &ccedil;&ouml;zmek sureti ile servislerimize ait kodları, yazılı ve g&ouml;rsel i&ccedil;erikleri ve diğer telif hakkı i&ccedil;eren doneler ile patent, faydalı model vb. sertifikalarla korunan verileri ele ge&ccedil;irmeyeceğinizi ve izinsiz dağıtmayacağınızı,</li>
                    <li>Başkalarından Odit servislerinde kullandıkları hesap bilgilerini istemeyeceğinizi, zorla bu bilgileri alı koymayacağınızı ve &ccedil;almayacağınızı,</li>
                    <li>Diğer kullanıcılara ait sizinle hata eseri dahi olsa paylaşılan veya sizin servislerde yaşanan arızalardan faydalanarak elde edeceğiniz kişisel bilgileri hangi formatta ve t&uuml;rde olursa olsun toplamayacağınızı, saklamayacağınızı ve paylaşmayacağınızı,</li>
                    <li>Servisleri izinsiz ticari ve kumar faaliyetlerine konu etmeyeceğinizi (&ouml;rneğin sezon sonu kimin birinci olacağının bahsine girmek gibi),</li>
                </ul>
                <p>beyan ve kabul etmiş olmakla beraber, bu faaliyetlerinizden &ouml;t&uuml;r&uuml; Odit, servislerimize ait diğer kullanıcılar ve diğer anlaşmalı 3. partilerimizin karşılaşacağı zararlardan mesul olduğunuzu peşin ve kesin olarak kabul etmiş sayılırsınız.</p>
                <p>Neyin bir hak ihlali olup, olmadığına, ve hak ihlalleri karşısında hangi cezai işlemin uygulanacağına yine Odit'in karar vereceğini kabul etmiş sayılırsınız.</p>
                <p>Odit t&uuml;m hak ihlallerinde hesabınızı kapama t&uuml;mden silme, erişim kısıtlaması getirme vb.hakları elinde bulundurur.</p>
                <h3 id='1.2.-hesabinizin-iptali-ve-kaldirilmasi'>1.2. Hesabınızın İptali ve Kaldırılması</h3>
                <p>Odit, size &ouml;nceden bilgi verme zorunluluğu olmaksızın Kullanım Şartları'na uymamanız veya bu konuda makul bir ş&uuml;phe bulunması halinde servislerimize ait hesaplarınızı sınırlayabilir, askıya alabilir, sonlandırabilir, değiştirebilir veya servisler i&ccedil;erisinde kısmi engellemeler uygulayabilir, paylaştığınız i&ccedil;eriklere erişiminizi engelleyebilir, bu i&ccedil;erikleri silebilir veya bu i&ccedil;eriklere genel erişim kısıtlamaları koyabilir.</p>
                <p>Odit, hesaplarınıza ve hesabınız &uuml;zerindenyaptığınız paylaşımlara kısıtlamalar getirmenin yanı sıra kullanım şartlarımıza uymamanızdan &ouml;t&uuml;r&uuml; tarafımıza yansıyacak b&uuml;t&uuml;n maddi ve manevizararlardan sizi sorumlu tutarak hakkınızda yasaların gereğince eş değer cezai nitelikleri i&ccedil;erecek yasal yollara başvuracaktır.</p>
                <p>Odit, 180 g&uuml;nden uzun s&uuml;re ile inaktif kalmış (servislerimizin hi&ccedil;biri ile etkileşime ge&ccedil;memiş) hesapları &ouml;nceden bildirmeksizin ve daha &ouml;nceden paylaşılan veri ve dosyaları yedeklemeksizin silme hakkını elinde tutar.</p>
                <p>Odit, herhangi bir servisini vermeyi istediği zaman durdurabilir, servislerini yayından kaldırabilir veya ge&ccedil;ici olarak askıya alabilir. Odit'in durdurduğu servislere erişiminiz anında kısıtlanacağı gibi kullanım lisanslarınızda kaldırılacaktır. Odit, kalıcı veya ge&ccedil;ici olarak askıya sunduğu servislerinde bulunan bakiyelerinizi iade etmekle y&uuml;k&uuml;ml&uuml; değildir; şu kadar ki ge&ccedil;ici olarak askıya alınan servisler tekrar yayına ge&ccedil;erse mevcut bakiyeleriniz korunmuş olarak bu servislere erişiminiz tekrardan sağlanacaktır.</p>
                <p>Kullanmakta olduğunuz servis erişiminizi veya Odit hesabınızı istediğiniz zaman kalıcı olarak kapatabilirsiniz. Odit, hesabınızı kapatmak i&ccedil;in destek hattımıza talep kaydı oluşturmanız gerekmektedir.</p>
                <h2 id='2.-mulkiyet'>2. M&Uuml;LKİYET</h2>
                <h3 id='2.1.-oyunlar-ve-uygulamalar'>2.1. Oyunlar ve Uygulamalar</h3>
                <p>Oyunların isimleri, kodlar, oyun i&ccedil;inde kullanılan t&uuml;m metin ve g&ouml;rseller, video vb. multimedya &ouml;ğeleri, tasarımlar, grafikler, oyun karakterleri, objeleri, karakter isimleri, diyaloglar, sloganlar, konseptler, senaryolar, animasyonlar, m&uuml;zik kompozisyonları, ses ve g&ouml;r&uuml;nt&uuml; efektleri, film şeritleri, iş akışları, dok&uuml;mantasyonlar, ekran g&ouml;r&uuml;nt&uuml;leri ve video kayıtları da dahil olmak &uuml;zere oyun ve uygulamalarımızı kullandığınız ortam ve kanallardan bağımsız olarak oyun ve uygulamalarımızda kullanılan t&uuml;m unsurların kullanım ve dağıtım hakları ve fikri ve diğer m&uuml;lkiyet hakları Odit'e aittir.</p>
                <h3 id='2.2.-hesaplar'>2.2. Hesaplar</h3>
                <p>İşbu s&ouml;zleşmeyi kabul etmeniz durumunda veya Odit servislerin birinde hesap a&ccedil;manız halinde işbu s&ouml;zleşmede belirtilen şartlarla kısıtlı kalmamak &uuml;zere, Odit servislerinde a&ccedil;tığınız herhangi bir hesabınızın ve hesabınızdan paylaştığınız doysa vb. bilgi akışlarının m&uuml;lkiyet hakkı Odit'e ait olacak olup, bu hesaplardan ticari gelir beklentiniz olmayacağını ve hesaplarınızı sadece Odit Kullanım Şartları'na uygun kullanacağınızı kabul ve beyan etmiş sayılırsınız.</p>
                <h3 id='2.3.-sanal-urunler'>2.3. Sanal &Uuml;r&uuml;nler</h3>
                <p>Odit, servislerinde yer alan t&uuml;m yazılı, g&ouml;rsel ve işitsel i&ccedil;eriğin lisans sahibi, lisanslı kullanıcısı veya doğrudan sahibidir Sanal &uuml;r&uuml;nler ve oyun i&ccedil;i para birimleri de dahil olmak &uuml;zere, bu &uuml;r&uuml;n ve para birimlerinin işbu s&ouml;zleşmede izin verilenden farklı şekilde kullanmayacağınızı, kendi paylaştığınız i&ccedil;erikler dahil, hi&ccedil; bir i&ccedil;erik &uuml;zerine hak talep etmeyeceğinizi kabul ve beyan etmiş sayılırsınız.</p>
                <h2 id='3.-kullanici-icerigi'>3. KULLANICI İ&Ccedil;ERİĞİ</h2>
                <h3 id='3.1.-kullanici-iceriklerinin-gonderimi'>3.1. Kullanıcı İ&ccedil;eriklerinin G&ouml;nderimi</h3>
                <p>'Kullanıcı İ&ccedil;eriği', kullanıcı tarafından Odit servisleri &uuml;zerinden yapılan t&uuml;m analog ve dijital iletişim, metin, g&ouml;rsel ve işitsel dosya paylaşımı, her t&uuml;r veri ve fiziksel paketi i&ccedil;erir. Sohbet ve forum metinleri, uygulama etkileşim kayıtları ve desteki, bilgi, y&ouml;netim vb. birimlerimizle iletişim ve sosyal medya hesaplarımızın g&ouml;nderileri altına yapılan yorumları i&ccedil;ermektedir.</p>
                <p>Odit servislerini kullanırken yarattığınız ve transferini ger&ccedil;ekleştirdiğiniz t&uuml;m verilerin ve bilgilerin,</p>
                <ul>
                    <li>ger&ccedil;ek ve yanıltıcı olmaması,</li>
                    <li>uluslararası ve yasal kanunlara uygun olması,</li>
                    <li>etik olması,</li>
                    <li>Odit ve ilgili 3. parti şartlarına uygun olması,</li>
                    <li>telif hakkı başkasına ait ise, bunların paylaşımı ve dağıtımı i&ccedil;in yazılı iznin bulunması ve alınması,</li>
                    <li>vir&uuml;s, trojan vb. zararlı yazılımlardan arındırılmış olması,</li>
                    <li>ve herhangi kişisel bir verinizi i&ccedil;eren i&ccedil;eirklerin Odit Gizlilik S&ouml;zleşmesi koşullarına uygun olarak Odit ve ilgili 3. partiler tarafından işlenebileceğini</li>
                </ul>
                <p>kabul ve beyan etmiş sayılırsınız.</p>
                <h4 id='3.1.1.-icerik-moderasyonu'>3.1.1. İ&ccedil;erik Moderasyonu</h4>
                <p>Odit, servislerimizi kullanırken paylaştığınız i&ccedil;erikleri, işbu s&ouml;zleşme şartlarına ve yasalara uygunluğu konusunda i&ccedil;eriklerin paylaşımı yapıldıktan sonra rastgele veya şikayet halinde kontrol etmekle birlikte, yaptığınız paylaşımların mesuliyeti tamamen size aittir. Servislerimizi kullanırken paylaştığınız i&ccedil;eriklerin yasalara uygunluğundan sorumlu olduğunuz gibi, yaşanabilecek herhangi bir zarardan da sorumlu olacağınızı kabul ve beyan etmiş sayılırsınız. Odit'in g&ouml;nderdiğiniz i&ccedil;eriklerin tamamını g&ouml;nderim tamamlanmadan &ouml;nce kontrol edemeyeceğini ve kontrol etse dahi bunun tarafınıza yasa &ouml;n&uuml;nde bir koruma sağlamayacağını anladığınızı beyan edersiniz.</p>
                <p>İşbu s&ouml;zleşmeye taraf olmayı kabul ettiğiniz anda servislerimiz &uuml;zerinden paylaşacağınız t&uuml;m i&ccedil;eriklerin a&ccedil;ık olarak kontrol edilme ve kaydedilme hakkını Odit'e vermiş sayılır ve paylaşımlarınızın hi&ccedil;bir gizlilik seviyeleri ile ilgili tarafımızdan &ouml;zel koruma talebinde bulunamayacağını beyan edersiniz.</p>
                <h3 id='3.2.-servis-ve-diger-kullanicilar-tarafindan-bilgilerin-kullanilmasi'>3.2. Servis ve Diğer Kullanıcılar Tarafından Bilgilerin Kullanılması</h3>
                <h4 id='3.2.1.-halka-acik-paylasimlar'>3.2.1. Halka A&ccedil;ık Paylaşımlar</h4>
                <p>Odit servisleri forum, sohbet kanalları, blog vb. ve halka a&ccedil;ık interaktif veya tek y&ouml;nl&uuml; iletişim olanakları sunabilir. Bu iletişim kanallarında g&ouml;r&uuml;şlerinizi paylaşabilirsiniz, fakat paylaştığınız g&ouml;r&uuml;şlerinizin ve fikirlerinizin m&uuml;lkiyet haklarının korumamız altında olmadığı gibi, diğer kullanıcıların bu paylaşımlarınızı ne şekilde kullanacağının garantisinin de tarafımızdan verilmediğini anladığınızı beyan etmiş olursunuz. Ayrıca, forum, sohbet vb. kanallarda yaptığınız paylaşımların size a&ccedil;tığı zararlardan Odit mesuliyet kabul etmeyecektir.</p>
                <h3 id='3.2.2.-kullanici-iceriginin-sorumlulugu'>3.2.2. Kullanıcı İ&ccedil;eriğinin Sorumluluğu</h3>
                <p>Servislerimizde ve servislerimize bağlı diğer t&uuml;m ortamlarda paylaştığınız i&ccedil;eriklerin yasalara uyumluluğu, Kullanım Şartları'na uyumluluğu, Gizlilik S&ouml;zleşmesi'ne uyumluluğundan siz sorumlusunuzdur. Odit, yasalara ve kurallara uymayan bir i&ccedil;eriğinizi tespit ettiğinde, i&ccedil;eriği g&ouml;ndermenizi engelleme, g&ouml;nderilmiş bir i&ccedil;eriği yayından alma veya &ouml;nceden haber vererek yada haber vermeksizin d&uuml;zenleme hakkını saklı tutar.</p>
                <h4 id='3.2.3.-Odit'>3.2.3. Odit'e Sağladığınız Lisans</h4>
                <p>Servislerimizi kullanırken oluşturduğunuz yazılı, işitsel ve g&ouml;rsel t&uuml;m i&ccedil;eriklerin lisans, kullanım, satış ve tekrar satış haklarını, oldukları şekliyle veya &uuml;zerlerinde değişiklik yapılmış olması fark etmeksizin geri d&ouml;nd&uuml;r&uuml;lemez ve kalıcı şekilde, herhangi bir zaman ve coğrafi sınır olmaksızın Odit'e &uuml;cretsiz olarak vermiş olursunuz. T&uuml;m lisansların bedellerinin tamamının &ouml;dendiğini ve bu Odit'e ge&ccedil;en t&uuml;m hakların, Odit tarafından &uuml;cretli veya &uuml;cretsiz olarak 3. partilere transfer edilebilir olduklarını beyan edersiniz. Odit'e sunduğunuz lisans dahilinde Odit herhangi bir servisinde paylaştığınız i&ccedil;erikleri olduğu gibi veya modifiye ederek, tekrar oluşturarak, d&uuml;zelterek, kopyalayarak,&ccedil;oğaltabilir, herhangi bir mecradan yayınlayabilir, satabilir, lisanslayabilir ,alt lisanslar oluşturabilir, kiralayabilir ve sergileyebilir.</p>
                <p>Bunlarla kısıtlı kalmamak sureti ile Odit'e isminizin gizliliğinden feragat ettiğinizi beyan ve kabul edersiniz. Odit işbu bilgileri yayınlarken isminiz ile ilişkilendirebilir ve isminizi a&ccedil;ık olarak paylaşabilir. Yasaların &ouml;n g&ouml;rd&uuml;ğ&uuml;nce yarattığınız i&ccedil;erik ile ilgili t&uuml;m haklarınızı Odit'e devretmiş olduğunuzu ve bu i&ccedil;eriklerle ilişkilendirilmemeniz i&ccedil;in gerekli t&uuml;m gizlilik haklarınızdan feragat ettiğiniz kabul edersiniz.</p>
                <p>Servislerimizde oluşturduğunuz yazılı, işitsel ve g&ouml;rsel i&ccedil;eriklerin hi&ccedil;birinin sahipliği hakkında hak iddia etmemekteyiz ve kişisel veya ticari kullanımlarınızı kısıtlamamktayız . İşbu i&ccedil;erikleri &uuml;cretsiz veya &uuml;cretli, kişisel veya ticari ama&ccedil;larla dağıtabilir, satabilir, yayınlayabilir ve sergileyebilirsiniz. Şu kadar ki, i&ccedil;eriğinde Odit, servisleri ve &ccedil;alışanları ile ilgili asılsız beyanlar veya gizli bilgiler bulunan i&ccedil;erikleri derhal i&ccedil;erikleri paylaştığınız, yayınladığınızı ve sergilediğiniz alanlardan kaldırmak zorunda olduğunuzu kabul ve beyan edersiniz.</p>
                <p>Odit, paylaştığınız veya oluşturduğunuz i&ccedil;eriklerin telif haklarını ve yaslara uyumlu olup, olmadığını kontrol ile y&uuml;k&uuml;ml&uuml; değildir. Paylaştığınız ve oluşturduğunuz i&ccedil;eriklerde telif hakkı veya diğer yasalara aykırı durumlar oluşması halinde t&uuml;m maddi ve manevi zararlardan sorumlu olduğunuzu a&ccedil;ık olarak kabul ve beyan edersiniz.</p>
                <h3 id='3.3.-kullanici-etkilesimleri'>3.3. Kullanıcı Etkileşimleri</h3>
                <p>Servislerimizi kullanırken etkileşim i&ccedil;ine girdiğiniz veya iletişim kurduğunuz diğer kullanıcılar ile aranızda ge&ccedil;en t&uuml;m etkileşim ve iletişimlerden ve bu etkileşim ve iletişimler sırasındaki davranışlarınızın tamamından siz sorumlusunuzdur.</p>
                <p>Odit, servislerini kullanan kullanıcıların birbirleri arasındaki iletişim ve etkileşimleri g&ouml;zlemleme, gerek duyduğu halde veya şikayet halinde araya girerek y&ouml;netme hakkını saklı tutmakla birlikte bu hakkı kullanım zorunluluğu bulunmamaktadır.</p>
                <p>Etkileşim ve iletişimleriniz ile ilgili yapacağımız t&uuml;m denetimlerde Odit ile eksiksiz bir şekilde işbirliği yapacağınızı, bilgi gizlemeyeceğinizi, &ccedil;arpıtmayacağınızı veya yalan bilgi paylaşımında bulunmayacağınızı beyan edersiniz.</p>
                <p>Bir veya daha fazla kullanıcı ile aranızda oluşan ithilaflarda Odit, servisleri, &ccedil;alışanları, iş ortakları ve hissedarlarının sorumluluğu olmadığını peşinen kabul ve beyan edersiniz.</p>
                <h2 id='4.-ucretler-ve-satin-alim-sartlari'>4. &Uuml;CRETLER VE SATIN ALIM ŞARTLARI</h2>
                <h3 id='4.1.-satin-alimlar'>4.1. Satın Alımlar</h3>
                <p>Servislerimizde satın alımları 'ger&ccedil;ek' para birimleri veya bu para birimleri karşılığında alacağınız transfer edilemez, lisanslanamaz servise &ouml;zel sanal para birimleri ile ger&ccedil;ekleştirebilirsiniz.</p>
                <p>Sanal para birimlerini sadece Odit servisleri veya bu sanal para birimlerinin satışı i&ccedil;in tarafımızdan lisans almış 3. parti kişi veya kurumlardan alabilirsiniz.</p>
                <p>Odit, sanal para birimlerinin fiyatlarını ve bunların satıldığı satıldığı 3. parti iş ortaklarını istediği zaman haber vermeksizin denetleme, y&ouml;netme ve d&amp;uumal;zenleme hakkına sahiptir.</p>
                <p>Servis i&ccedil;erisinde &ouml;zellikle belirtilmediği s&uuml;rece sanal para birimlerinin ve bu para birimleri ile alınabilen oyun i&ccedil;in sanal &uuml;r&uuml;nlerin tekrar satışı, başka bir kullanıcıya devri ve iadesi m&uuml;mk&uuml;n değildir.</p>
                <p>Servislerimizde yapılan t&uuml;m satın alımlar sondur ve iade edilemez.</p>
                <h3 id='4.2.-odemeler-ve-ucretler'>4.2. &Ouml;demeler ve &Uuml;cretler</h3>
                <p>Vergiler, 3. parti servis &ouml;demeleri, faiz vb. finansal maliyetler ve kargo maliyetleri de dahil olmak &uuml;zere bir satın alım sırasında oluşacak t&uuml;m masrafları &ouml;deyeceğinizi kabul edersiniz.</p>
                <p>Odit'in, kendi isteğiniz ile veya Odit kararı ile kapatılması halinde, size herhangi bir kullanılmamış &uuml;r&uuml;n ile ilgili iade tanımlama zorunluluğu bulunmamaktadır.</p>
                <h2 id='5.-servis-guncellemeleri'>5. SERVİS G&Uuml;NCELLEMELERİ</h2>
                <p>Servislerimizin s&uuml;rekli geliştiğini kabul ederek, servislerimizi kullanmaya devam edebilmek i&ccedil;in sizlere sunduğumuz g&uuml;ncellemeleri kabul ederek cihazlarınıza indirmeyi kabul edersiniz. Ayrıca Odit'in bilginiz veya onayınız dahilinde olmasa bile, cihazlarınızda y&uuml;kl&uuml; servislerimizi otomatik olarak g&uuml;ncelleme hakkı olduğunu kabul edersiniz. Cihazınızda y&uuml;kl&uuml; bazı &uuml;&ccedil;&uuml;nc&uuml; parti uygulamaların ise manuel olarak zaman zaman tarafınızdan servislerimizi kesintisiz olarak kullanılabilmesi amacı ile g&uuml;ncellenmesi gerekebilir.</p>
                <h2 id='6.-garantiler'>6. GARANTİLER</h2>
                <p>Odit sorumluluklarını kısıtlamadan, servislerimiz olduğu şekli ile ve erişilebilir olduğu kadarı ile sunulmaktadır. Servislerimizin kullanımı, erişilebilirliği, yeterliliği ve yararlılığı ile ilgili bir garantimiz bulunmamaktadır.</p>
                <p>Odit, servislerimize istediğiniz yer ve zamanda erişebilmenizi garanti etmediği gibi, servislerimizin hatasız ve kusursuz olmak durumunda olmadığını kabul edersiniz. Şu kadar ki, servislerimizin vir&uuml;s, truva atı ve benzeri yasal olmayan yollardan ve izinsiz bilgi toplayan mekanları i&ccedil;ermediğini garanti ederiz.</p>
                <h2 id='7.-sorumlulugun-sinirlandirilmasi'>7. SORUMLULUĞUN SINIRLANDIRILMASI</h2>
                <p>Yasalarca izin verilen seviyede, Odit, servislerimizi kullanırken yaşayacağınız maddi ve manevi zararlardan sorumlu tutulamaz. Servislerimizin s&uuml;rekliliğindeki aksamalar, bulunduğunuz konum veya zamanda servislerimizin erişime a&ccedil;ık olmaması ve benzeri durumlarda yaşayacağınız finansal kayıplardan Odit sorumlu değildir.</p>
                <p>Herhangi bir durumda servislerimizle ilgili kullanımlarınızda Odit sadece servise &ouml;dediğiniz mebla kadar sorumlu olacak olup, servislerimizi kullanarak elde ettiğiniz satış ve diğer gelirlerinizin kaybından kesinlikle sorumlu tutulamaz.</p>
                <p>Odit'e karşı olası bir ithilaf durumunuzda servislerimizi kullanmayı hemen durdurma ve hesaplarınızı iptal etme zorunluluğunuz olduğunu ve gerekli işlemleri gecikmeden yapacağınızı kabul ve beyan edersiniz.</p>
                <p>Hak ihlalleri kasıtlı veya ihmalkar davranışlarınıza atfedilmediği m&uuml;ddet&ccedil;e tarafınıza a&ccedil;ılan t&uuml;m yasal s&uuml;re&ccedil;lerde veya tarafımıza a&ccedil;ılan ve sorumlusu sizin olduğunuz yasal s&uuml;re&ccedil;lerde Odit haklarını koruyacağınız ve kollayacağınızı, mahkeme ve avukatlık masrafları gibi oluşabilecek masrafları &uuml;stleneceğinizi kabul ve beyan edersiniz.</p>
                <h2 id='8.-uyusmazlik-cozumleri-ve-hukuk'>8. UYUŞMAZLIK &Ccedil;&Ouml;Z&Uuml;MLERİ VE HUKUK</h2>
                <p>Servislerimizin kullanımından dolayı Odit ile aranızda bir ithilaf oluştuğu taktirde ilk &ouml;nce bizimle iletişime ge&ccedil;menizi tavsiye ederiz.</p>
                <p>Bulunduğunuz konumdan ve sahip olduğunuz vatandaşlıktan bağımsız olarak Odit ile olan ithilaflarınızın T&uuml;rkiye Cumhuriyeti İstanbul ili mahkemelerince &ccedil;&ouml;z&uuml;mleneceğini kabul edersiniz.</p>
                <h2 id='9.-bolunebilirlik'>9. B&Ouml;L&Uuml;NEBİLİRLİK</h2>
                <p>Siz ve Odit, işbu Kullanım Şartları'nın herhangi bir maddesinin bulunduğunuz konumdaki yasalara uygun olmadığı veya y&uuml;r&uuml;rl&uuml;ğe alınamayacak durumda olduğunu durumlarda, işbu Kullanım Şartları'nın diğer yetki alanlarındaki b&uuml;t&uuml;nl&uuml;ğ&uuml;ne zarar gelmeyecek şekilde ge&ccedil;ersiz h&uuml;k&uuml;mlerin y&uuml;r&uuml;rl&uuml;kten kaldırılacağını veya h&uuml;k&uuml;mlerin ge&ccedil;erli olacak şekilde yeniden d&uuml;zenlenebileceğini kabul ve beyan edersiniz.</p>
                <h2 id='10.-genel-sartlar'>10. GENEL ŞARTLAR</h2>
                <h3 id='10.1.-gorevlendirme'>10.1. G&ouml;revlendirme</h3>
                <p>Odit, &ouml;nceden bilgi vermiş olsun olmasın, işbu Kullanım Şartları'nı, Gizlilik S&ouml;zleşmesi'ni ve diğer bağlıs&ouml;zleşmeleri t&uuml;m&uuml;yle veya kısmi olarak başka bir kişi veya kuruma delege edebilir. Siz işbu Kullanım Şartları, Gizlilik S&ouml;zleşmesi ve diğer bağlı s&ouml;zleşmelerden doğan mesuliyetlerinizi başka bir kişi veya kuruma, Odit'ten yazılı izin almadan devredemezsiniz.</p>
                <h3 id='10.2.-destekleyici-sozlesmeler'>10.2. Destekleyici S&ouml;zleşmeler</h3>
                <p>Odit, işbu s&ouml;zleşmeye ek olarak destekleyici ve tamamlayıcı s&ouml;zleşmeler, şartnameler ve y&ouml;netmelikler yayınlayabilir ve bu s&ouml;zleşmeler, şartnameler ve y&ouml;netmelikler kendilerinden &ouml;nce gelen s&ouml;zleşmelerin &uuml;st&uuml;nde şartlar i&ccedil;erebilir.</p>
                <h3 id='10.3.-butunluk'>10.3. B&uuml;t&uuml;nl&uuml;k</h3>
                <p>İşbu Kullanım Şartları, Gizlilik S&ouml;zleşmesi ve diğer bağlı s&ouml;zleşmeler bir b&uuml;t&uuml;n olarak Odit ve sizin aranızdaki ilişkiyi, anlaşmayı ve y&uuml;k&uuml;ml&uuml;l&uuml;kleri belirlemektedir ve daha &ouml;nceden tarafınız ile Odit arasında yapılmış t&uuml;m s&ouml;zl&uuml; veya yazılı anlaşmaların yerine ge&ccedil;er.</p>
                <h2 id='10.4.-feragat-etme'>10.4. Feragat Etme</h2>
                <p>Odit'in işbu s&ouml;zleşme ve bağlı s&ouml;zleşmelerden doğan haklarını ne sebep olursa olsun uygulamaması veya uygulayamaması haklarından feragat ettiği anlamına gelmez. Odit'in herhangi bir ithilafta yazılı olarak haklarından feragat etmesi, gelecekte yaşanacak benzer ithilaflarda da haklarından feragat etmiş olduğu anlamına gelmemektedir.</p>
                <p>İşbu s&ouml;zleşme ve bağlı s&ouml;zleşmelerde net olarak belirtilmediği s&uuml;rece, tarafınızca s&ouml;zleşmelerde yapılabilecek d&uuml;zenlemeleri Odit yasal ve bağlayıcı olarak g&ouml;rmeyecektir.</p>
                <h2 id='10.5.-ihtarname'>10.5. İhtarname</h2>
                <p>Yasal konularda sizi, servislerimizde yarattığınız hesaplara ait beyan ettiğiniz iletişim bilgilerinde yer alan posta adresiniz, telefon numaranız veya e-posta adresiniz &uuml;zerinden g&ouml;ndereceğimiz ihtarname ile bilgilendiririz.</p>
                <p>Tarafımıza yasal bir konu ile ilgili ihtarname g&ouml;ndermek istiyorsanız Harju maakond, Tallinn, Kesklinna linnaosa, Ahtri tn 12, 10151 adresine 'Odit Hukuk Departmanının' dikkatine başlıklıklı, noter tasdikli bir şekilde taleplerinizi iletebilirsiniz. İşbu y&ouml;ntem dışında g&ouml;ndereceğiniz hi&ccedil;bir ihtarname, Odit tarafından yasal olarak bağlayıcı olmayacaktır.</p>
                <h3 id='10.6.-adil-cozumler'>10.6. Adil &Ccedil;&ouml;z&uuml;mler</h3>
                <p>İşbu s&ouml;zleşme ve bağlı alt s&ouml;zleşmelerde tarafınıza sunulan haklar sizin Odit ile olan ilişkinize &ouml;zel olup, hi&ccedil;bir şekilde devredilemez ve değiştirilemez.</p>
                <p>İhtiyati tedbir veya diğer adil telafi talep etme veya Hizmetin veya herhangi bir Odit servisinin &ccedil;alışmasını, bunlarla bağlantılı olarak yayınlanan herhangi bir reklamın veya diğer materyallerin istismarını veya Hizmetin veya herhangi bir i&ccedil;eriğin veya diğer materyallerin istismar edilmesini emretme veya kısıtlama haklarından geri alınamaz şekilde feragat edersiniz. Hizmet aracılığıyla kullanılan veya g&ouml;r&uuml;nt&uuml;lenen materyalden doğan zararlarınızı ve B&ouml;l&uuml;m 7 ile sınırlandırıldığı maddi tazminat taleplerinizi sınırlamayı kabul edersiniz.</p>
                <h3 id='10.7.-mucbir-sebep'>10.7. M&uuml;cbir Sebep</h3>
                <p>Odit, gerek servislerinin sunumunda gerekse yasal s&uuml;re&ccedil;lerin ilerlemesi esnasında kendi kontrol&uuml; dışında yaşanabilecek gecikmelerden dolayı sorumlu tutulamaz. Bu gecikmelere doğal afet, savaş durumu, ter&ouml;r olayı, grev, yangın, sel, susuzluk, ulaşım hizmetlerinde aksama, yakıt veya enerji sıkıntısı, &ccedil;alışan sayısında beklenmedik azalma ve hammadde eksikliği ile sınırlı olmaksızın herhangi bir toplumsal afet, kaza veya savaş durumu dahildir.</p>
                <h3 id='11.-iletisim'>11. İletişim</h3>
                <h3 id='11.1.-resmi-duyuru-kanallari'>11.1. Resmi Duyuru Kanalları</h3>
                <p>Odit, işbu s&ouml;zleşme ve bağlı s&ouml;zleşmelerde yapacağı d&uuml;zenlemeleri, servislerimizde oluşturduğunuz hesaplarda belirttiğiniz e-posta adresleriniz ve https://odit.com adresi &uuml;zerinde yer alan ilgili sayfalarda duyuracaktır.</p>
                <p><span data-slate-zero-width='n' data-slate-length='0'>&nbsp;</span></p>
            </div>
            </div>

            <hr>

            <router-link :to="'/privacy?playerId=' + this.$route.query.playerId">Gizlilik Sözleşmesi</router-link>
            <router-link :to="'/guide?playerId=' + this.$route.query.playerId">Yardım</router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      content: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.table {
  border: 1px solid #C4C4C4;
  text-align: left;

  tr {
    td, th {
      border-bottom: 1px solid #C4C4C4;
      border-right: 1px solid #C4C4C4;
      padding: 5px 15px;
    }  
  }
}
</style>
