<template>
  <div class="under-construction">
    <div class="container mx-auto max-w-sm flex flex-col items-center">
      <h1 class="mt-8 font-semibold mb-2">ÖZEL SERVİS!</h1>
      <p class="mt-2 mb-8">Erişim için iletişime geçin.</p>
      <img class="w-64" src="@/assets/under-construction.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>