import Vue from 'vue';
import Axios from 'axios';
import store from '@/store'

Axios.defaults.baseURL = process.env.API_LOCATION
Axios.defaults.headers.common.Accept = '*/*'
Axios.defaults.headers.common['Content-Type'] = 'application/json'

let apiKey = 'cb483a11197bf7af68e460454d2d4de1'

const API_DEV_LOCATION = "http://127.0.0.1:8001"
const API_PROD_LOCATION = "https://api.bip.adam-asmaca.com"

const instance = Axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? API_DEV_LOCATION : API_PROD_LOCATION
});

instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers['X-Api-Key'] = apiKey
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use(
  function(response) {
    if(response.data.token) {
      store.dispatch("setSessionToken", response.data.token);
    }

    return response;
  }, 
  function(error) {
      //handle error
      if(error.response) {
        switch(error.response.status) {
          case 401:
            Vue.prototype.$notify({
              message: error.response.data.message,
              type: 'danger'
            });
            store.dispatch("auth/logout", Vue.router.currentRoute.fullPath);
            break;
          default:
            return Promise.reject(error);
        }
      }
      else {
        return Promise.reject(error);
      }
  }
);

Vue.$http = instance

Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return Axios
  },
})

export default instance;