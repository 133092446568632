import Vue from "vue"
import Vuex from "vuex"
import _ from "lodash"
import appApi from "@/api/app"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    heartbeat: true,
    isLoading: false,
    sessionToken: null,
    authenticated: false,
    gamePlay: null,
    productGroups: [],
    player: {
      id: null,
      alias: "tsemmui",
      avatarUrl: "/cdn/avatar/tsemmui-avatar.png",
      powerups: {
        rewind: 2,
        open: 18,
        ticket: 10,
        immortality: {
          isActive: true,
          validUntil: "2019-08-24T14:15:22Z",
        },
      },
      coins: 0,
      playerStanding: {
        currentRank: null,
        score: null
      },
      appSettings: {
        locale: "tr-tr",
        gameEffects: true,
        gameMusic: true,
        cdnUrl: "https://cdn.adam-asmacam.com",
        notificationSettings: {
          leaderboardNotifications: "push",
          gameResultNotifications: "push",
          marketingNotifications: "push",
          paymentNotifications: "email",
          systemNotifications: "email",
          legalNotifications: "email",
        },
        theme: "default",
      },
      isImmortal: false,
    },
    leaderboard: []
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated
    },
    hasGamePlay(state) {
      return null != state.gamePlay
    },
    getRandomWord(state) {
      let index = _.random(0, state.words.length - 1)
      return state.words[index]
    },
  },
  mutations: {
    SET_API_HEARTBEAT(state, payload) {
      state.heartbeat = payload
    },
    SET_SESSION_TOKEN(state, payload) {
      state.sessionToken = payload
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload
    },
    SET_AUTHENTICATED(state, payload) {
      state.authenticated = payload
    },
    SET_APP_SETTINGS(state, payload) {
      state.player.appSettings = payload
    },
    SET_PLAYER_INFO(state, payload) {
      state.player = payload
    },
    UPDATE_PLAYER_INFO(state, payload) {
      var player = state.player

      player.id = payload.id
      player.alias = payload.alias
      player.avatarUrl = payload.avatar
      player.powerups = payload.powerups
      player.coins = payload.coins
      player.isImmortal = payload.isImmortal
      player.playerStanding = payload.playerStanding

      state.player = player
    },
    UPDATE_PLAYER_POWERUP(state, payload) {
      state.player.powerups = payload
    },
    SET_GAMEPLAY(state, payload) {
      state.gamePlay = payload
    }
  },
  actions: {
    openLoader({ commit }) {
      commit("SET_IS_LOADING", true)
    },
    heartbeat({ commit }) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return appApi.heartbeat().then((response) => {
          commit("SET_API_HEARTBEAT", true)
          commit("SET_IS_LOADING", false)

          resolve(response)
        }).catch(error => {
          commit("SET_API_HEARTBEAT", false)
          commit("SET_IS_LOADING", false)

          reject(error)
        })
      })
    },
    init({ commit }) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      if(urlParams.get('playerId')) {
        commit('SET_AUTHENTICATED', true)
      }

      return new Promise((resolve) => {
        resolve(true)
      })
    },
    createTrainingGame({ commit }, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return appApi.createTrainingGame(payload.playerId).then((response) => {
          commit("SET_GAMEPLAY", response.data.result.set)
          commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
          commit("SET_IS_LOADING", false)

          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    getGameInfo({ commit }, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return appApi.retrieveGame(payload).then((response) => {
          commit("SET_IS_LOADING", false)
          resolve(response.data.result.set)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    getGame({ commit }, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return appApi.retrieveGame(payload).then((response) => {
          if('200.0404' == response.data.code) {
            reject('Undefined')
          }

          let gamePlay = response.data.result.set
          
          if(gamePlay) {
            commit("SET_GAMEPLAY", gamePlay)
            commit("UPDATE_PLAYER_INFO", gamePlay.player)
          }

          commit("SET_IS_LOADING", false)
          
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    async updateGame({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return appApi.updateGame(payload).then((response) => {
          commit("SET_GAMEPLAY", response.data.result.set)
          commit("UPDATE_PLAYER_INFO", response.data.result.set.player)

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    finalizeGame({commit}, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return appApi.finalizeGame(payload).then((response) => {
          let gamePlay = response.data.result.set

          commit("SET_GAMEPLAY", null)
          commit("UPDATE_PLAYER_INFO", gamePlay.player)
          commit("SET_IS_LOADING", false)

          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    usePowerup({ commit }, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return appApi.usePowerup(payload).then((response) => {
          commit("UPDATE_PLAYER_POWERUP", response.data.result.set.player.powerups)
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    getWord({ state, dispatch }, payload) {
      if(null == state.gamePlay) {
        if(payload.gameType == 0) {
          return dispatch('createTrainingGame', payload).then(() => {
            return new Promise((resolve) => {
              resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
            })
          }).catch((err) => {
            return new Promise((reject) => {
              reject(err)
            })
          })
        }else{
          return dispatch('getGame', payload).then(() => {
            return new Promise((resolve) => {
              resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
            })
          }).catch((err) => {
            return new Promise((reject) => {
              reject(err)
            })
          })
        }
      }else{
        return new Promise((resolve) => {
          resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
        })
      }

    }
  }
})
