<template>
    <div>
        <div v-if="this.readyToShow" class="py-4 flex items-center">
            <div class="w-screen flex flex-col items-center" v-if="this.gamePlay && this.gamePlay.status == 'f'">
                <template v-if="!isExpired && this.gamePlay.winStatus == 'w'">
                    <h1 class="my-10 font-semibold text-3xl text-center">TEBRİKLER !</h1>
                    <img class="mb-10" src="@/assets/icon-win.svg" alt="">
                </template>
                <template v-else-if="!isExpired && this.gamePlay.winStatus == 'l'">
                    <h1 class="my-10 font-semibold text-3xl text-center">ÜZGÜNÜZ !</h1>
                    <img class="mb-10" src="@/assets/icon-sad.svg" alt="">
                </template>
                <template v-else-if="isExpired">
                    <h1 class="my-10 font-semibold text-3xl text-center">OYUN SÜRESİ DOLDU !</h1>
                    <img class="mb-10" src="@/assets/icon-sad.svg" alt="">
                </template>
                <div class="hangman-word flex justify-center mb-4">
                    <template  v-if="wordSpaceCount == 0 || (wordArray.length <= 12 && wordSpaceCount > 0)">
                        <div class="flex mx-2" v-for="word, i in wordSplit" :key="`word-${i}`">
                            <span class="hangman-word__char text-success mt-3" :class="{'is-space': character === ' ', 'text-success': gamePlay.winStatus == 'w', 'text-danger': gamePlay.winStatus == 'l'}" v-for="(character,index) in word" :key="`char-${index}`">
                                {{character}}
                            </span>
                        </div>
                    </template>
                    <template v-if="wordArray.length > 12 && wordSpaceCount > 0">
                        <div class="flex flex-col items-center">
                        <div class="flex" v-for="word, i in wordSplit" :key="`word-${i}`">
                            <span class="hangman-word__char text-success mt-3" :class="{'is-space': character === ' ', 'text-success': gamePlay.winStatus == 'w', 'text-danger': gamePlay.winStatus == 'l'}" v-for="(character,index) in word" :key="`char-${index}`">
                            {{character}}
                            </span>
                        </div>
                        </div>
                    </template>
                </div>

                <div class="my-6 text-center px-2" v-html="rewardMsg" />
                
            </div>

            <div class="mt-20 mx-auto" v-else>
                <p class="text-center">Oyun bulunamadı.</p>
            </div>
        </div>
        
    </div>
  
</template>

<script>
import { mapActions } from 'vuex'

export default {
    props: {
        gameId: {
            type: String,
            required: true
        }
    },
    computed: {
        wordArray: function() {
            return this.gamePlay ? this.decryptWord(this.gamePlay.wordToGuess).toString().toLocaleUpperCase('TR').split("") : []
        },
        rewardMsg: function() {
            var msg = ''

            var reward = null;

            switch(this.gamePlay.transaction.rewardCode) {
                case 'aa01.0.gb.g':
                    reward = 'GÜNLÜK 1 GB!'
                    break;

                case 'aa02.5.gb.g':
                    reward = 'GÜNLÜK 2.5 GB!'
                    break;

                case 'aa05.0.gb.g':
                    reward = 'GÜNLÜK 5 GB!'
                    break;

                case 'aa10.0.gb.g':
                    reward = 'GÜNLÜK 10 GB!'
                    break;

                case 'aa00.5.gb.g':
                default:
                    reward = 'GÜNLÜK 500 MB!'
                    break;
            }

            if(this.gamePlay.winStatus == 'w') {
                msg += '<h1 class="text-center home-promo mb-6">Tüm harfleri doğru bilerek <br><span class="home-promo__circle-gray">ödülü</span> kazandın !</h1>'
            }else{
                msg += '<h1 class="text-center home-promo mb-6">Oyunu kazanamasan da <br><span class="home-promo__circle-gray">hediyen</span> bizden !</h1>'
            }

            msg += `<h1 class="text-center home-promo my-4 font-semibold text-3xl"><span class="home-promo__underline-thin-orange pb-2">${reward}</span></h1>`
            msg += '<h1 class="text-center home-promo my-6">Ödülün tanımlandığında <br>sana <span class="home-promo__underline-gray pb-1"><b class="font-semibold text-2xl">SMS</b> ve <b class="font-semibold text-2xl">BİP</b></span> üzerinden <br>bilgi vereceğiz.</h1>'

            msg += `<p class="mt-10"><b>İşlem Numarası:</b> <br>${this.gamePlay.transaction.orderId}</p>`

            return msg
        },
        isExpired() {
            if(this.gamePlay && this.gamePlay.finishedAt) {
                let gameStartedAt = Date.parse(this.gamePlay.startedAt)
                let finishedAt = Date.parse(this.gamePlay.finishedAt)
                let hours = Math.abs(finishedAt - gameStartedAt) / 36e5;

                return hours > 24
            }
            
            return false;
        }
    },
    data() {
        return {
            readyToShow: false,
            gamePlay: null,
            wordSplit: [],
            guess: null,
            wordSpaceCount: 0,
            rightCharacters: [],
            wrongCharacters: []
        }
    },
    watch: {
        wordArray() {
            this.guess = this._.map(this.wordArray, (character) => {
                return character != " " ? "" : character;
            });
            let count = this._.filter(this.wordArray, function(o) { 
                if (o == ' ') return o 
            })

            this.wordSpaceCount = count.length;

            let words = []
            let copyArray = this._.cloneDeep(this.wordArray);
            let i = 0;
            while(copyArray.length > 0) {
                if(copyArray[i] == ' ') {
                    words.push(copyArray.slice(0, i));
                    copyArray.splice(0, i + 1);
                    i = 0;
                }
                else if (i == copyArray.length - 1) {
                words.push(copyArray.slice(0, i + 1));
                copyArray.splice(0, i + 1);
                i = 0;
                }
                i++;
            }

            this.wordSplit = [...words]

            this.guess = this._.map(this.wordSplit, (word) => {
                return this._.map(word, (character) => {
                return this.rightCharacters.includes(character) ? character : (character != " " ? "" : character);
                })
            })
        }
    },
    created() {
        this.getGameInfo({gameId: this.gameId, playerId: this.$route.query.playerId}).then((gamePlay) => {
            this.gamePlay = gamePlay
            this.readyToShow = true
        }).catch(() => {
            this.$router.push({name: 'NotFound', query: {playerId: this.$route.query.playerId}})
        })
    },
    methods: {
        ...mapActions(["getGameInfo"]),
        decryptWord(cryptedWord) {
            let d1 = new Buffer(cryptedWord, 'base64')
            let d2 = d1.toString().split("").reverse().join("");
            return new Buffer(d2, 'base64')
        }
    }
}
</script>

<style>

</style>